import Utils from "./Utils.js";

const productionValues = {
	name: 'Tele AR',
	shortName: 'Tele AR',
	title: 'Tele AR',
	tagline: "3D FaceTime with friends in AR",
	description: "Use augmented reality to teleport everyone into your room on your phone",
	url: 'https://tele.xyz',
	appURL: 'https://apps.apple.com/us/app/tele-by-spatial/id1538463918', // http://appstore.com/tele
	appName: 'App'
}

const stagingValues = {
	name: 'Tele AR',
	shortName: 'Beta',
	title: 'Tele AR Beta',
	tagline: "3D FaceTime with friends in AR",
	description: "Use augmented reality to teleport everyone into your room on your phone",
	url: 'https://staging.tele.xyz',
	appURL: 'https://testflight.apple.com/join/bjUvNT0A',
	appName: 'Beta App'
}

export default (Utils.isStaging() ? stagingValues : productionValues);