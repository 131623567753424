import axios from 'axios';

import * as THREE from 'three';
import JSZip from 'jszip';

import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js';

/* -*- Mode: js; tab-width: 2; indent-tabs-mode: nil; js-indent-level: 2 -*- */

/* Copyright (C) Itseez3D, Inc. - All Rights Reserved
 * You may not use this file except in compliance with an authorized
 * license Unauthorized copying of this file, via any medium is
 * strictly prohibited Proprietary and confidential
 * UNLESS REQUIRED BY APPLICABLE LAW OR AGREED BY ITSEEZ3D, INC. IN
 * WRITING, SOFTWARE DISTRIBUTED UNDER THE LICENSE IS DISTRIBUTED ON
 * AN "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
 * EITHER EXPRESS OR IMPLIED
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * Written by Itseez3D, Inc. <support@itseez3D.com>, October 2018
 */

function FileLoader(bearer, playerUID) {
	this.bearer = bearer;
	this.playerUID = playerUID;
}

Object.assign(FileLoader.prototype, {
	load: function(url, onLoad, onProgress, onError) {
		if (!this.bearer) {
			if (!!onError) {
				return onError('empty bearer');
			}
			return;
		}

		var auth = 'Bearer ' + this.bearer;

		axios({
			method: "GET",
			headers: {
				Authorization: auth,
				'X-PlayerUID': this.playerUID
			},
			responseType: "arraybuffer",
			// params: { lod: 7 },
			url: url
			// data: authForm
		}).then( (res) => {
			// console.log('file loader res', res.data, res)
			onLoad(res.data, res);
			return
		}).catch( (error) => {
			return onError(error)
		})
	}
});

// =============================================================================

function TextureLoader(fileLoader) {
	// console.log('this fired')
	this.fileLoader = fileLoader;
}

Object.assign(TextureLoader.prototype, {
	load: function(url, onLoad, onProgress, onError) {
		this.fileLoader.load(url, onTextureLoaded, onProgress, onError);

		function onTextureLoaded(data, event) {
			var arrayBufferView = new Uint8Array(data);
			// var mimeType = event.target.getResponseHeader('content-type') || mime;
			let mimeType = event.headers["content-type"];
			var blob = new Blob([arrayBufferView], {type: mimeType});
			var imageUrl = URL.createObjectURL(blob);

			var img = new Image();
			img.onload = function onImgLoad() {
				// window.open(imageUrl,'Image','width=300px,height=300px,resizable=1');
				// console.log('image size', img.width, img.height);

				URL.revokeObjectURL(imageUrl);

				var texture = new THREE.Texture();
				texture.image = img;
				texture.format = THREE.RGBAFormat;
				texture.needsUpdate = true;

				if (!!onLoad) {
					onLoad(texture);
				}
			}

			img.src = imageUrl;
		}
	}
});

// =============================================================================

function GeometryLoader(fileLoader) {
	this.fileLoader = fileLoader;
}

Object.assign(GeometryLoader.prototype, {
	load: function(url, filename, onLoad, onProgress, onError) {

		this.fileLoader.load(url, onMeshLoaded, onProgress, onError);

		function onMeshLoaded(ply, event) {
			// var status = event.target.status;
			// if (status == 204) {
			// 	if (!!onLoad) {
			// 		return onLoad(null);
			// 	}
			// 	return;
			// }
			// var contentType = event.target.getResponseHeader('Content-type');
			let contentType = event.headers["content-type"];

			switch (contentType) {

				case 'application/octet-stream':
					// already handled
					break;

				case 'application/zip':
					// console.time('unzip');

					let zip = new JSZip();
					zip.load(ply);
					let files = Object.keys(zip.files);

					if (files.length <= 0) {
						console.error('empty zip');
						if (!!onError) {
							return onError('empty zip');
						}
						return;
					}

					filename = filename || files[0];
					// console.log("filename", url, filename, Array.isArray(filename));
					if (Array.isArray(filename)) {
						// ply = filename.map((fn) => zip.file(fn).asArrayBuffer());
						// console.log('filneame', filename, files)
						ply = filename.map((fn, index) => {
							// this will list all blendshape filenames, useful for calling specified array
							// console.log("files", fn, index, files[index]);
							return zip.file(fn).asArrayBuffer();
						});
					} else {
						// console.log("filename", url, ply, Array.isArray(files));
						// ply = zip.file(filename).asArrayBuffer();
						ply = zip.file(files[0]).asArrayBuffer();
					}

					// console.timeEnd('unzip');
					break;

				default:
					var msg = 'unhandled Content-type:' + contentType
					console.warn(msg);

					if (!!onError) {
						return onError(msg);
					}

				return;
			}

			var geometry = null;

			if (Array.isArray(filename)) {
				geometry = ply.map((p, key) => {
					let fetchedPoly = new PLYLoader().parse(p)
					// remove last 4 characters
					fetchedPoly.name = filename[key].slice(0, -4);;
					return fetchedPoly;
				});

				// console.log('geo return', filename, geometry);
			} else {
				geometry = new PLYLoader().parse(ply);
			}

			if (!!onLoad) {
				return onLoad(geometry);
			}
		}
	}
});



const AvatarSDKLoaders = {
	FileLoader,
	TextureLoader,
	GeometryLoader
};

export default AvatarSDKLoaders;


// available blendshapes key via
// https://developer.apple.com/documentation/arkit/arfaceanchor/blendshapelocation
// 00 eyeWideRight.ply
// 01 noseSneerRight.ply
// 02 eyeWideLeft.ply
// 03 eyeLookOutRight.ply
// 04 browDownLeft.ply
// 05 mouthUpperUpLeft.ply
// 06 mouthFunnel.ply
// 07 mouthRollLower.ply
// 08 browOuterUpLeft.ply
// 09 mouthRight.ply
// 10 jawRight.ply
// 11 browOuterUpRight.ply
// 12 mouthFrownRight.ply
// 13 mouthRollUpper.ply
// 14 browDownRight.ply
// 15 mouthSmileLeft.ply
// 16 eyeLookUpLeft.ply
// 17 mouthPressRight.ply
// 18 mouthLeft.ply
// 19 noseSneerLeft.ply
// 20 mouthUpperUpRight.ply
// 21 mouthShrugUpper.ply
// 22 mouthDimpleRight.ply
// 23 eyeSquintLeft.ply
// 24 eyeBlinkLeft.ply
// 25 eyeLookUpRight.ply
// 26 cheekPuff.ply
// 27 mouthPucker.ply
// 28 eyeBlinkRight.ply
// 29 mouthShrugLower.ply
// 30 cheekSquintRight.ply
// 31 mouthFrownLeft.ply
// 32 mouthStretchLeft.ply
// 33 eyeLookDownRight.ply
// 34 mouthLowerDownRight.ply
// 35 mouthSmileRight.ply
// 36 eyeLookInLeft.ply
// 37 eyeLookInRight.ply
// 38 jawForward.ply
// 39 mouthPressLeft.ply
// 40 jawLeft.ply
// 41 mouthDimpleLeft.ply
// 42 mouthClose.ply
// 43 jawOpen.ply
// 44 eyeLookOutLeft.ply
// 45 eyeSquintRight.ply
// 46 eyeLookDownLeft.ply
// 47 browInnerUp.ply
// 48 cheekSquintLeft.ply
// 49 mouthLowerDownLeft.ply
// 50 mouthStretchRight.ply