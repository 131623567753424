// https://css-tricks.com/intro-firebase-react/
import Utils from "./Utils.js";
import Firebase from 'firebase/app';

require("firebase/auth");
// require("firebase/database");
require("firebase/firestore");
require("firebase/analytics");
require("firebase/functions");

const firebaseConfig = {
	staging: {
	    apiKey: "AIzaSyC1kv2tNK67r5LedHmRlehAlIiqp9PAQH8",
	    authDomain: "tele-69ee9.firebaseapp.com",
	    databaseURL: "https://tele-69ee9.firebaseio.com",
	    projectId: "tele-69ee9",
	    storageBucket: "tele-69ee9.appspot.com",
	    messagingSenderId: "591434445540",
	    appId: "1:591434445540:web:579161313ff00bb80132f1",
	    measurementId: "G-CY9M90C8KM"
	},
	prod: {
		apiKey: "AIzaSyCXkkXElIYKSRBP4U8F2A0V0Yxm2vcZPyI",
		authDomain: "tele-production.firebaseapp.com",
		databaseURL: "https://tele-production.firebaseio.com",
		projectId: "tele-production",
		storageBucket: "tele-production.appspot.com",
		messagingSenderId: "176903378795",
		appId: "1:176903378795:web:c24bc5760f3c8fa1385e2e",
		measurementId: "G-S4ZFTJ0F2X"
	}
}

// if it includes staging or local hit staging
const config = Utils.isStaging() ? firebaseConfig.staging : firebaseConfig.prod;

Firebase.initializeApp(config);

export const Provider = new Firebase.auth.GoogleAuthProvider();
// Provider.addScope('https://www.googleapis.com/auth/calendar.events');

export const Auth = Firebase.auth();

// this will break the app if Messages is init on browsers that don't support push(safari, ios)
// check if messaging.isSupported before initing messaging
// https://medium.freecodecamp.org/how-to-add-push-notifications-to-a-web-app-with-firebase-528a702e13e1
// export const Messaging = Firebase.messaging.isSupported() ? Firebase.messaging() : null;

export const Database = Firebase.firestore();
// export const RealtimeDatabase = Firebase.database();
export const Analytics = Firebase.analytics();
export const Functions = Firebase.functions();

// for local debugging
// if (window.location.hostname === "localhost") {
//   Database.useEmulator("localhost", 8025);
//   Functions.useEmulator("localhost", 5001);
// }

export const Timestamp = Firebase.firestore.Timestamp;
export const FieldValue = Firebase.firestore.FieldValue;
export const GeoPoint = Firebase.firestore.GeoPoint;

export const BaseConsoleURL = `https://console.firebase.google.com/u/0/project/${config.projectId}`
export const FirestoreConsoleURL = `${BaseConsoleURL}/firestore/data`

// enable persistence
// Database.enablePersistence().catch(function(err) {
// 	if (err.code === 'failed-precondition') {
// 		console.log("Multiple tabs open, persistence can only be enabled in one tab at a a time.", err);
// 	} else if (err.code === 'unimplemented') {
// 		console.log("// The current browser does not support all of the features required to enable persistence", err);
// 	}
// });

// Database.disableNetwork();
export default Firebase;