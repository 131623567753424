import * as React from 'react';
import {Helmet} from "react-helmet";
import Values from "./Values.js";
// import SplashImage from '../res/tele.jpg'

class MetaTags extends React.Component {
	render() {
		return (
			<Helmet>
				<title>{this.props.data.title ? this.props.data.title : this.props.title}</title>
				<meta name="description" content={this.props.data.description ? this.props.data.description : this.props.description} />
				<link rel="canonical" href={this.props.data.url ? this.props.data.url : this.props.url} />

				<meta property="og:url" content={this.props.data.url ? this.props.data.url : this.props.url} />
				<meta property="og:title" content={this.props.data.title ? this.props.data.title : this.props.title} />
				<meta property="og:description" content={this.props.data.description ? this.props.data.description : this.props.description} />
				<meta property="og:image" content={this.props.data.image ? this.props.data.image : this.props.image} />
				<meta property="og:type" content={this.props.data.type ? this.props.data.type : this.props.type} />
			</Helmet>
		);
	}

}

MetaTags.defaultProps = {
	url: window.location.href,
	title: `${Values.title}: ${Values.tagline}`,
	description: "",
	image: `${Values.url}${"/tele.jpg"}`,
	type: "website"
};

export default MetaTags;
