import React from 'react';
import { withRouter } from "react-router-dom";
import API from './components/API.js';
import Utils from './components/Utils.js';
import Values from "./components/Values.js";
import MetaTags from './components/MetaTags.js'

// components
import Scene from './components/Scene.js';

// document.body.classList.add("circle");

class User extends React.Component {

	constructor(props)
	{
		super(props);

		this.state = {
			showDebug: Utils.isLocalhost(),
			autoRotate: !Utils.isUserAgent("snap"),

			loadedPeople: 0,
			showInvite: false,
			filteredPeople: {},
			rearFacingCamera: false,
			showRoster: false,
			showBackground: true,
			thumbnailURL: null,
			roster: {},
			sayCheese: false,
			title: {
				name: null,
				animation: null
			},
			selfie: null,
			allowMovement: null,
			meta: {},
			status: {
				message: null,
				animation: null
			}
		};
		window.prerenderReady = false;
	}

	componentDidMount() {
		// get roster
		let filteredPeople = {};
		const roomID = this.props.match.params.id;

		API.getRoom(roomID, (data) => {
			if (data && data.users) {
				// merge invites and users datas

				let userDataPromises = data.users.map((UID) => {
					// console.log("data", UID)
					return new Promise((resolve, reject) => {
						API.getUser(UID, (userData) => {
							if (userData) {
								filteredPeople[UID] = userData;
							}
							resolve();
						});
					})
				})

				if (!data.invites) data.invites = [];

				let inviteDataPromises = data.invites.map((UID) => {
					// console.log("invite data", UID)
					return new Promise((resolve, reject) => {
						API.getUserInvite(UID, (userData) => {
							if (userData) {
								filteredPeople[UID] = userData;
							}
							resolve();
						});
					})
				})

				Promise.all([...userDataPromises, ...inviteDataPromises]).then(() => {
					// console.log("user promise ended", filteredPeople)
					this.setState({filteredPeople});

					const rosterTitle = Object.keys(filteredPeople).map(user => filteredPeople[user].firstName);

					// set meta data
					this.setState({
						meta: {
							title: `Tele AR w/ ${rosterTitle.join(', ')}`,
							image: data.thumbnail ? data.thumbnail.imageURL : null
						}
					}, () => {
						window.prerenderReady = true;
					})
				});
			} else {
				const roomIDToNumber = parseInt(roomID);
				// if the url is a number, just fake it out
				// console.log("is it a num", !isNaN(roomID), roomID)
				if (!isNaN(roomID)) {
					console.log("doesnt exist so making random based on number", roomID, roomIDToNumber)
					let filteredPeople = API._generateRandomUsers(roomIDToNumber);
					this.setState({filteredPeople});
					this.setState({sayCheese: true});
				} else {
					console.log("this room doesn't exist and cant make a number")
				}
			}
		})

		this.refs.scene.goToSnap();
	}

	componentWillUnmount() {
		console.log("home unmounting");
		this.refs.scene.stop();
	}

	render() {

		return (
			<div>
				<MetaTags data={this.state.meta} />

				<div
					id="scene"
					className="full-viewport absolute"
				>
					<Scene
						ref="scene"
						showDebug={this.state.showDebug}
						backgroundColor="random"
						match={this.props.match}
						people={this.state.filteredPeople}
						autoRotate={this.state.autoRotate}
						parentStatusMethod={this._updateStatus}
					/>
				</div>

				{ this.state.sayCheese ?
					<div id='url2png-cheese' className="absolute z4 white h0 bg-red"></div>
					:
					null
				}

				{
					Utils.isMobile() ?
						<button
							className={`absolute right-0 hover white p3`}
							onClick={ () => {
								this.refs.scene.toggleOrientation();
								this.setState({ rearFacingCamera: !this.state.rearFacingCamera })
							}}
						>
							<i className={`material-icons scaleIn`}>{this.state.rearFacingCamera ? "touch_app" : "360"}</i>
						</button>
					: null
				}

				<div className={`${navigator.userAgent === "snap" ? "hide" : ""}`}>
					<div className={`flex flex-column mb-r exclusion absolute bottom-0 centerX`}>
						<button
							className={`scaleIn pill-button z2`}
							onClick={(e) => {
								e.preventDefault();
								window.location.href=Values.appURL;
							}}
						>
							{`Join the ${Values.shortName}`}
						</button>
					</div>

					{
						// this.state.showInvite ?
						// 	<AvatarCreator
						// 		parentUserMethod={this._updateInvites}
						// 	/>
						// :
						// <div className={`exclusion absolute top-0 right-0 p3`}>
						// 	<button
						// 		className={`hover white p2`}
						// 		onClick={ () => {
						// 			this._toggleInvite()
						// 		}}
						// 	>
						// 		<i className={`material-icons scaleIn`}>{ this.state.showInvite ? "person_add_disabled" : "person_add_alt_1" }</i>
						// 	</button>
						// </div>
					}

				</div>
			</div>
		);
	}

	_toggleInvite() {
		const toggleState = this.state.showInvite ? false : true;
		this.setState({showInvite: toggleState});
	}

	_updateStatus = (message, animation="fadePulse") => {
		const totalPeople = Object.keys(this.state.filteredPeople).length;

		if (message === "got yo face") {
			this.setState(prevState => ({
				loadedPeople: prevState.loadedPeople + 1
			}), () => {
				// console.log("got you", totalPeople, this.state.loadedPeople)
				// this callback is async and fires too fast sometimes if no avatars are real
				if (totalPeople === this.state.loadedPeople) {
					console.log("say cheese fries", `${this.state.loadedPeople}/${totalPeople}`)
					this.setState({sayCheese: true})
				}
			});

		}

		this.setState({
			status: {
				message: this.state.loadedPeople  + " / " + totalPeople,
				animation: animation
			}
		});
	}

	_filterPeople = (newPeople) => {
		// console.log('filtering people', newPeople)
		// let filteredPeople = {};
		// filteredPeople = !newPeople ? this.state.people : newPeople;
		if (newPeople) {
			this.setState({filteredPeople: newPeople});
		}
	}

	_updateInvites = (newPeople) => {
		console.log('update people', newPeople, this.state.filteredPeople)
		if (newPeople) {
			API.addInviteToRoom(this.props.match.params.id, newPeople);

			let filteredPeople = {
				...this.state.filteredPeople,
				...newPeople
			}

			this.setState({
				filteredPeople: filteredPeople
			});

			console.log('final people', newPeople, filteredPeople, this.state.filteredPeople)
		}
	}

}

export default withRouter(User);
