import Utils from './Utils.js';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

class SceneBackground {
	constructor(sceneObject, statusMethod) {
		this.sceneObject = sceneObject || null;
		this.gltf = null;
		this.mixer = null;
		this.statusMethod = statusMethod || null;
	}

	init(cb) {
		this._loadGLTF(this.sceneObject, cb);
	}

	_loadGLTF = (url, cb) => {
		// load preset body
		let loader = new GLTFLoader();

		// Load a glTF resource
		loader.load(url, (gltf) => {

			gltf.scene.traverse( ( child ) => {
				if (child.name === "Neck") {
					console.log("child name", child.name)
					this.neck = child;
				}

				if ( child.isMesh ) {
					child.castShadow = true;
					child.receiveShadow = true;

					if (Utils.isNight()) {
						child.material.metalness = 1;
					} else {
						child.material.metalness = .4;
					}

					if (child.name === "head") {
						this.defaultHead = child;
					}
				}
			})

			this.gltf = gltf;

			// let scaleFactor = 5;
			// this.gltf.scene.position.set(0, -120 * scaleFactor, 0);
			// this.gltf.scene.position.set(0, 0, 0);
			// this.gltf.scene.scale.set(scaleFactor, scaleFactor, scaleFactor);
			this.playAnimations(this.gltf);

			gltf.scene.name = "object_scene";

			cb(gltf.scene);
		},
			// called while loading is progressing
			(xhr) => {
				// console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
				if (xhr.loaded === xhr.total) {
					console.log('done loading gltf')
				}
			},
			// called when loading has errors
			(error) => {
				console.log( 'An error happened', error );
			}
		);
	}

	playAnimations() {
		if (this.gltf) {
			this.mixer = this.mixer || new THREE.AnimationMixer(this.gltf.scene);

			this.gltf.animations.forEach((clip) => {
				this.mixer.clipAction(clip).play();
			});
		}
	}

	updateMixer(delta) {
		if ( this.mixer ) this.mixer.update( delta );
	}

	_pulseObject = (object) => {
		object.traverse( child => {
			if (child.materials) {
				console.log(child, child.materials)
				// child.materials[0].transparent = true;
				// child.materials[0].opacity = 1 + Math.sin(new Date().getTime() * .0025);//or any other value you like
				// child.visible = false;
			}
			return
		});
	}

	_hideObject = (object) => {
		object.traverse( child => {
			// if (child instanceof THREE.Mesh) {
			if (child.isMesh) {
				child.visible = false;
			}
			return
		});
	}
}

export default SceneBackground;