import * as React from 'react';
import { withRouter } from "react-router-dom";

import Utils from "./components/Utils.js";
import Values from "./components/Values.js";
import Scene from './components/Scene.js';
import Profile from './components/Profile.js';
import MetaTags from './components/MetaTags.js'

import Splash from './res/tele.glb'

import PrivacySVG from './res/privacy.svg'
import TermsSVG from './res/terms.svg'
import TwitterSVG from './res/twitter.svg'

class Home extends React.Component {
	constructor() {
		super();

		this.state = {
			loading: false,
			currentItem: '',
			username: '',
			items: [],
			isEntering: false,
			meta: {},
			user: null
		}

		this.bgColor = Utils.isNight() ? "bg-black" : null;
		// console.log(this.bgColor);

		// prerender.io flag
		window.prerenderReady = false;
	}

	componentDidMount() {
		this.setState({
			meta: {
				title: `${Values.title}: ${Values.tagline}`,
				description: Values.description,
				type: "website"
			}
		}, () => {
			window.prerenderReady = true;
		});
	}

	render() {
		return (
			<div>
				<MetaTags data={this.state.meta} />

				<div
					className={`flex items-center justify-center flex-column ${Utils.isNight() ? "bg-night" : null }`}
					style={{
						height: "100vh"
					}}
				>

					<div
						className="full-viewport absolute"
					>
						<Scene
							match = {this.props.match}
							ref="scene"
							bg={Splash}
						/>
					</div>

					<div
						className="footer z4 absolute bottom-0 mb2-r exclusion"
					>
						<span
							className="pr2 inline-block white normal"
							style={{
								display: "inline",
								verticalAlign: "super"
							}}
						>
							By <a
								href="https://spatial.io"
								target="_blank"
								rel="noopener noreferrer"
								title="Spatial.io"
							>
								<strong>Spatial</strong>
							</a>
						</span>

						<span className="socialIcons">
							<a
								className="pr2 inline-block hover"
								href="mailto:tele@spatial.io"
								target="_blank"
								rel="noopener noreferrer"
								title="Contact"
							>
								<i
									className={`material-icons block`}
									style={{
										width: "24px",
										height: "24px"
									}}
								>
									alternate_email
								</i>
							</a>

							<a
								className="pr2 inline-block hover"
								target="_blank"
								rel="noopener noreferrer"
								href="https://twitter.com/spatialxr"
								title="Twitter"
							>
								<img
									className="invert"
									style={{width: "auto"}}
									width="24"
									height="24"
									alt="twitter"
									src={TwitterSVG}
								/>
							</a>

							<a
								className="inline-block hover"
								target="_blank"
								rel="noopener noreferrer"
								href="https://spatial.io/privacy"
								title="Privacy"
							>
								<img
									className="invert"
									style={{width: "auto"}}
									width="24"
									height="24"
									alt="privacy"
									src={PrivacySVG}
								/>
							</a>

							<a
								className="pl1 inline-block hover"
								target="_blank"
								rel="noopener noreferrer"
								href="https://spatial.io/terms"
								title="Terms"
							>
								<img
									className="invert"
									style={{width: "auto"}}
									width="24"
									height="24"
									alt="terms"
									src={TermsSVG}
								/>
							</a>
						</span>
					</div>

					<div className={`flex flex-column mb-r exclusion absolute bottom-0 ${this.state.avatarGenerating ? "scaleOut" : "scaleIn"}`}>
						<button
							className="pill-button z2"
							onClick={(e) => {
								e.preventDefault();
								window.location.href=Values.appURL;
							}}
						>{`Get the ${Values.appName}`}
						</button>

					</div>

					{/*
					{ this.state.loading ?
						<h2 className="exclusion normal fadePulse">Hang tight...</h2>
						:
						<button className="pill-button z2 mb4" onClick={this.login}>Log In w/ Google</button>
					}
					*/}

					<div className="absolute top-0 right-0 pt3 mx3">
						<Profile
							show={true}
							showLogout={true}
							history={this.props.history}
						/>
					</div>
				</div>
			</div>

		);
	}


	handleChange(e) {
		/* ... */
	}
}
export default withRouter(Home);
