import React from 'react';
import { withRouter } from "react-router-dom";
import API from './components/API.js';
import Utils from './components/Utils.js';
import Profile from './components/Profile.js';
import AvatarDefault from './res/avatarDefault.svg';
import { FirestoreConsoleURL } from './components/Firebase';

class Admin extends React.Component {

	constructor(props)
	{
		super(props);

		this.state = {
			users: {},
			selectedUsers: null,
			filteredRooms: {},
			sortField: API.getLocalStorage("sortField") ? API.getLocalStorage("sortField") : "name",
			sortDirection: API.getLocalStorage("sortDirection") ? API.getLocalStorage("sortDirection") : -1,
			stats: {
				today: 0,
				week: 0,
				month: 0,
				total: 0
			},
			size: 0
		};

		document.body.style.overflow = "auto";
		this._sort = this._sort.bind(this)
	}

	componentDidMount() {
		API.getAdmin().then(() => {
			API.getUsers(false, (usersData) => {
				// console.log("got users", this.state.sortField)
				this.setState({
					users: usersData
				}, () => {
					setTimeout(this._sort(this.state.sortField, false), 10)
				});

				const defaultSelection = API.getLocalStorage("lastSelectedUsers") && usersData[API.getLocalStorage("lastSelectedUsers")[0]] ? API.getLocalStorage("lastSelectedUsers") : [Object.keys(usersData)[0]];

				this.setState({ selectedUsers: defaultSelection })
				this.setState({ size: [Object.keys(usersData).length] })
			});
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.selectedUsers !== prevState.selectedUsers) {
			API.setLocalStorage("lastSelectedUsers", this.state.selectedUsers);
			// console.log(this.state.selectedUsers)

			API.getRooms(this.state.selectedUsers, filteredRooms => {
				this.setState({ filteredRooms })
			})
		}

		if (this.state.size !== prevState.size) {
			const stats = {
				today: 0,
				week: 0,
				month: 0,
				total: this.state.size
			}

			Object.keys(this.state.users).forEach((uid) => {
				// console.log("user date", uid, this.state.users[uid].avatar.dateUpdated)
				const timeSinceToday = Utils.getTimeDaysFromToday(this.state.users[uid].avatar.dateUpdated)
				if (timeSinceToday === 0) {
					stats.today++
				}

				if (timeSinceToday > -7) {
					stats.week++
				}

				if (timeSinceToday > -30) {
					stats.month++
				}
			})
			this.setState({stats})
		}

	}

	componentWillUnmount() {
		console.log("admin unmounting");
	}

	render() {

		return (
			<div
				className="full-viewport-width absolute white"
			>

				<div className="nav z2 exclusion absolute right-0">
					<div className="pt3 mx3">
						<Profile
							show={true}
							showLogout={true}
							history={this.props.history}
						/>
					</div>
				</div>

				<div id="admin-panel" className={`${Utils.isMobile() ? "m2" : "m4"}`}>

					<div id="roster" className="sm-col sm-col-12 md-col-8 lg-col-8 mb4">
						<div className="">
							<button
								className="mx-auto block white pb2 exclusion"
								onClick={this._clearSelected.bind()}
							>
								View Rooms
							</button>
							<div className="center pb2">
								Today: {`${this.state.stats.today} Week: ${this.state.stats.week} Month: ${this.state.stats.month} Total: ${this.state.stats.total}`}
							</div>
						</div>

						<table className="mx-auto bg-none p2 exclusion border left-align">
							<thead>
								<tr>
									<th
										className="px2 hover"
										onClick={this._sort("name")}
									>
										Name
									</th>
									<th
										className="px2 hover"
										onClick={this._sort("phone")}
									>
										Phone
									</th>
									<th
										className="px2 hover"
										onClick={this._sort("date")}
									>
										Date
									</th>
									<th
										className="px1"
									>

									</th>
								</tr>
							</thead>

							<tbody>
								{
									Object.keys(this.state.users).map((UID) => (
										<tr
											key={`table_${UID}`}
											className={`hover ${this.state.selectedUsers && this.state.selectedUsers[0] === UID ? "bold bg-black" : "" }`}
											onClick={this._handleTableClick(UID)}
										>
											<td className="px2">{`${this.state.users[UID].firstName} ${this.state.users[UID].lastName}`}</td>
											<td className="px2">{`${this.state.users[UID].phoneNumber}`}</td>
											<td className="px2">{this.state.users[UID].avatar && this.state.users[UID].avatar.dateUpdated ? Utils.getLastSeen(this.state.users[UID].avatar.dateUpdated) : ""}</td>
											<td>
												<a target="_blank" rel="noopener noreferrer" href={`${FirestoreConsoleURL}/users/${UID}`}>
													<i className={`px1 material-icons scaleIn`}>more_horiz</i>
												</a>
											</td>
										</tr>
									))
								}
							</tbody>
						</table>

					</div>

					<div
						id="chats"
						className={`sm-col sm-col-12 md-col-4 lg-col-4 white mb4 flex flex-column justify-center align-middle center`}
					>
						<div className="">
							{ this.state.selectedUsers ?
								<div>
									{
										this.state.selectedUsers.map((key) =>
											<div
												key={key}
												className="pb2"
											>
												<button
													className="mt4 hover"
													onClick={this._goToProfile.bind()}
												>
													<img
														style={{
															width: "96px",
															height: "96px",
															backgroundImage: "https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"
														}}
														className="circle bg-red"
														alt="user"
														src={this.state.users[key].thumbnail.imageURL}
														title={key}
													/>
												</button>
												<div>
													<p
														className="hover inline uppercase white exclusion px2 py1 mb2 h5"
														onClick={this._goToProfile.bind()}
													>
														View profile
													</p>
													<i
														className="material-icons hover white"
														onClick={this._handleSnapRefresh("users", key)}
													>
														refresh
													</i>
												</div>
											</div>
										)
									}

								</div>
								: null
							}

							{
								Object.keys(this.state.filteredRooms).length === 0 ?
									<span>
										<div>
											<button
												className="bg-black white rounded shadow px2 py1 mb2"
												onClick={this._handleGo}
											>
												Create Room
											</button>
										</div>
										<span className="red">no rooms for</span>
									</span>
								:
									<span>
										<div>
											<button
												className="bg-white rounded shadow px2 py1 mb2"
												onClick={this._handleGo}
											>
												Go to Room
											</button>
										</div>
										<span className="">found rooms for</span>
									</span>
							}

							{ this.state.selectedUsers && this.state.users ?
								this.state.selectedUsers.map((key) =>
									<span className="pl1 bold" key={key}>{`${this.state.users[key].firstName} `}</span>
								)
								: null
							}
						</div>
						{
							Object.keys(this.state.filteredRooms).map((roomID) =>
								<div key={roomID}>
									<a className="block mt3" href={`${window.location.origin}/rooms/${roomID}`}>
										<img
											style={{
												width: "96px",
												height: "96px",
												backgroundImage: "https://media.giphy.com/media/17mNCcKU1mJlrbXodo/giphy.gif"
											}}
											className="circle bg-red"
											alt="room"
											src={this.state.filteredRooms[roomID].thumbnail ? this.state.filteredRooms[roomID].thumbnail.imageURL : AvatarDefault}
										/>
										<p>
											{
												this.state.filteredRooms[roomID].users.map((userID) => {
													return this.state.users[userID] ? this.state.users[userID].firstName : null
												}).join(', ')
											}
										</p>
										<p>
											{Utils.getLastSeen(this.state.filteredRooms[roomID].lastActiveTime, false)}
										</p>
										<p className="blue">{this.state.filteredRooms[roomID].invites ? Object.keys(this.state.filteredRooms[roomID].invites).join(' ') : null}</p>
									</a>
									<i
										className="material-icons inline hover white"
										onClick={this._handleSnapRefresh("rooms", roomID)}
									>
										refresh
									</i>
								</div>
							)
						}
					</div>
				</div>
			</div>
		);
	}

	_goToProfile = (userID) => {
		window.open(`/users/${this.state.selectedUsers[0]}`, "_self");
	}

	_goToRoom = (roomID) => {
		window.open(`/rooms/${roomID}`, "_self");
	}

	_createRoom = (roomID) => {
		window.open(`/rooms/${roomID}`, "_self");
	}

	_handleGo = (event) => {
		API.lookUpRoom(this.state.selectedUsers, (matchedRoom) => {
			this._goToRoom(matchedRoom);
		});
	}

	_handleChange = (event) => {
		const value = Array.from(event.target.selectedOptions, option => option.value);
		console.log(value)
		this.setState({ selectedUsers: value })
	}

	_handleTableClick = (key) => e => {
		// console.log("table click", [key])
		// console.log(...this.state.selectedUsers)
		this.setState({ selectedUsers: [key] })
	}

	_handleSnapRefresh = (collection, doc) => e => {
		// collections are either users or rooms
		// console.log("refresh click", collection, doc)
		API.refreshSnap(collection, doc).then(() => {
			console.log("snap done", collection, doc)
			window.location.reload(false)
		})
	}

	_sort = (sortField="name", flip=true) => e => {
		// console.log('sorting', sortField, this.state.sortField, sortDirection)
		let usersSorted = {}
		const sortDirection = flip ? this.state.sortDirection * -1 : this.state.sortDirection

		// sort functions
		usersSorted = Object.fromEntries(Object.entries(this.state.users).sort(([,a],[,b]) => {
			if (sortField === "name") {
				return a["firstName"] < b["firstName"] ? -1*sortDirection : 1*sortDirection
			} else if (sortField === "phone") {
				return a["phoneNumber"] < b["phoneNumber"] ? -1*sortDirection : 1*sortDirection
			} else if (sortField === "date") {
				const aTime = a.avatar.dateUpdated && a.avatar.dateUpdated.seconds ? a.avatar.dateUpdated.seconds : 0
				const bTime = b.avatar.dateUpdated && b.avatar.dateUpdated.seconds ? b.avatar.dateUpdated.seconds : 0
				return aTime*sortDirection - bTime*sortDirection
			} else {
				return a < b ? -1*sortDirection : 1*sortDirection
			}
		}))

		this.setState({
			users: usersSorted,
			sortField: sortField,
			sortDirection: sortDirection
		})

		API.setLocalStorage("sortField", sortField)
		API.setLocalStorage("sortDirection", sortDirection)
	}

	_clearSelected = () => {
		this.setState({ selectedUsers: null })
	}
}

export default withRouter(Admin);
