import * as React from 'react';
import { withRouter } from "react-router-dom";

import Utils from "./components/Utils.js";


class Redirect extends React.Component {
	constructor() {
		super();

		this.state = {
			loading: false,
			currentItem: '',
			username: '',
			items: [],
			isEntering: false,
			user: null
		}

		this.bgColor = Utils.isNight() ? "bg-black" : null;
	}

	componentDidMount() {
		console.log('mount');

		setTimeout( () => {
			this.props.history.push('/')
		}, 1600);

		this.setState({ isEntering: true });
	}

	render() {
		return (

			<div
				className={`flex items-center justify-center flex-column ${Utils.isNight() ? "bg-night" : null }`}
				style={{
					height: "100vh"
				}}
			>
				<div
					className={`border-2 absolute exclusion ${ this.state.loading ? "fadePulse delayShort" : null }`}
					style={{
						transform: "rotate(-45deg)",
						width: "50vw",
						height: "50vw",
						maxWidth: "50vh",
						maxHeight: "50vh"
					}}
				>
				</div>

				<div
					className="flex red z1 absolute exclusion"
					style={{
						fontSize: '44vw',
						fontWeight: '500',
						opacity: .95
					}}
				>
				404
				</div>

				<h2 className="exclusion normal fadePulse">Page isn't found</h2>
			</div>

		);
	}
}
export default withRouter(Redirect);
