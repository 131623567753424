// import { Database } from './Firebase';
import * as THREE from 'three';
import AvatarSDK from './AvatarSDK.js';
import Utils from './Utils.js';
import TWEEN from '@tweenjs/tween.js'
import API from "./API.js";

import glasses from '../res/glasses.glb'

import male_idle from '../res/male_idle_lod.glb'
import female_idle from '../res/female_idle_lod.glb'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import FresnelShader from "./FresnelShader.js";

const tweenDuration = 1600;
const loader = new GLTFLoader();

class SceneAvatar {
	constructor(personData, statusMethod) {
		this.personData = personData || null;
		this.shader = true;

		this.avatarHead = null;
		this.defaultHead = null;

		// meshes
		this.body = null;
		this.glasses = null;
		this.glassesPosition = [0, 0, 10]
		this.modelInfo = null;

		// bones
		this.hips = null;
		this.spine = null;
		this.neck = null;
		this.head = null;

		this.skin = null;
		this.skinColor = null;

		this.shirt = null;
		this.shirtColor = null;

		this.hair = null;
		this.hairColor = null;

		this.gltf = null;
		this.mixer = null;

		this.statusMethod = statusMethod || null;

		// animations
		this.tweenGroup = new TWEEN.Group();
		this.eyeBlink = true;
	}

	init(cb) {
		// todo: move this to its own component
		// console.log("init avatar", this.personData)

		// set and load bodytype
		this.personData.avatar && this.personData.avatar.bodyType === 1 ? this._loadBody(female_idle, cb) : this._loadBody(male_idle, cb);

		if (this.personData.avatar) {
			const modelAvatarPromise = new Promise((resolve, reject) => {
				AvatarSDK.getAvatar(this.personData.avatar.id, this.personData.avatar.userID, (meshData) => {
					// console.log("got baseMesh", meshData, this.defaultHead);
					this.statusMethod("got yo face", "scaleOut delay");

					if (!meshData) return resolve();
					this.avatarHead = meshData;
					this.neck.add(this.avatarHead);

					// if glasses set the position
					if (this.glasses && this.modelInfo && this.modelInfo.facial_landmarks_68) {
						// test landmarks
						// console.log("scale", this.avatarHead.userData.scaleFactor)
						let slicedResult = null
						let vectorHelper = null

						let pairedCoords = this.modelInfo.facial_landmarks_68.reduce((result, value, index, array) => {
							if (index % 3 === 0) {
								slicedResult = array.slice(index, index + 3)
								vectorHelper = new THREE.Vector3(...slicedResult);
								vectorHelper.multiplyScalar(this.avatarHead.userData.scaleFactor).add(new THREE.Vector3(0, 14, 5))
								// console.log("sliced", slicedResult, vectorHelper)
								result.push(vectorHelper);
							}
							return result;
						}, []);

						// ADD CUBE for help
						// const helperGroup = new THREE.Group();
						// const boxGeometry = new THREE.BoxBufferGeometry(0.5, 0.5, 0.5);
						// let boxMaterial = new THREE.MeshBasicMaterial({ color: '#ff4444' });

						// console.log(pairedCoords)
						// pairedCoords.map((val, i) => {

						// 	if (i === 27) {
						// 		boxMaterial = new THREE.MeshBasicMaterial({ color: '#44ff44' });
						// 		// cubeHelper.material.needsUpdate = true
						// 	} else {
						// 		boxMaterial = new THREE.MeshBasicMaterial({ color: '#ff4444' });
						// 	}

						// 	const cubeHelper = new THREE.Mesh(boxGeometry, boxMaterial);
						// 	cubeHelper.position.set(val.x, val.y, val.z)
						// 	return helperGroup.add(cubeHelper)
						// })
						// this.neck.add(helperGroup);

						this.neck.attach(this.glasses)
						this.glasses.rotation.set(0,0,0)

						this.glasses.scale.set(this.avatarHead.userData.scaleFactor/115, 1, 1)
						this.glasses.position.set(pairedCoords[27].x, pairedCoords[0].y - 0.5, pairedCoords[27].z)
					};

					this._hideObject(this.defaultHead);

					// default eye blink
					let have_morph_targets = !!this.avatarHead && this.avatarHead.morphTargetInfluences && this.avatarHead.morphTargetInfluences.length > 0;
					// console.log("eyeblink", have_morph_targets)

					if (have_morph_targets && this.eyeBlink) {
						if (this.eyeBlink) {
							this.eyeBlink = this._tweenRepeatBlendshape(this.avatarHead.morphTargetInfluences, "eyeBlinkLeft", 1, 200);
							this._tweenRepeatBlendshape(this.avatarHead.morphTargetInfluences, "eyeBlinkRight", 1, 200);
							this.eyeBlink = false;
						}
					}
					resolve()
				})
			})

			const modelInfoPromise = new Promise((resolve, reject) => {
				AvatarSDK.getAvatarModelInfo(this.personData.avatar.id, this.personData.avatar.userID, (modelInfo) => {
					// console.log("avatar model info", this.skin, modelInfo);
					// console.log("got model info", this.personData.avatar.id, this.personData.avatar.userID, modelInfo)
					if (!modelInfo) return resolve()
					this.modelInfo = modelInfo;

					this.skinColor = `rgb(${modelInfo.skin_color.red}, ${modelInfo.skin_color.green}, ${modelInfo.skin_color.blue})`;
					this.hairColor = `rgb(${modelInfo.hair_color.red}, ${modelInfo.hair_color.green}, ${modelInfo.hair_color.blue})`;

					API.getResource("colors", (data) => {
						this.shirtColor = new THREE.Color( parseInt(Utils.randomChoice(data.shirtColors))).convertGammaToLinear()
						this._updateSkin(this.skinColor)
					})

					// console.log("skin color", this.skinColor)
					// load and add glasses
					if (modelInfo.remove_glasses) {
						this._loadGlasses(glasses, (glassesMesh) => {
							this.glasses = glassesMesh;

							this.glassesPosition = {
								x: 0,
								y: (modelInfo.facial_landmarks_68[1] * 1000) - 1,
								z: (-modelInfo.facial_landmarks_68[2] * 1000) + 7
							};

							// console.log(glassesPosition, modelInfo)
							if (this.avatarHead) {
								this.glasses.position.set(this.glassesPosition.x, this.glassesPosition.y, this.glassesPosition.z);
							} else {
								this.glasses.position.set(0, 9.5, 12.5);
							}

							this.head.add(this.glasses);
						});
					}

					resolve()
				})
			})

			Promise.all([modelAvatarPromise, modelInfoPromise]).then(() => {
				// console.log("got info")
				this._updateSkin()
			})

		} else {
			// status method is quick way to send callback faces are loaded
			console.log("no avatarcode or playeruid")
			this.statusMethod("got yo face", "scaleOut delay");
		}

		// watch user movements (used for teleport.ist days)
		// if (this.personData.uid !== API.getCurrentUUID()) {
		// 	API.watchAvatarMovement((this.personData.uid), (data) => {
		// 		console.log("data", data)
		// 		this.animateFace(data);
		// 	});
		// } else {
		// 	console.log('skipping cause its me')
		// }

	}
	_loadGlasses = (url, cb) => {
		loader.load(url, (gltf) => {
			this.glasses = gltf.scene;
			// this.scene.add( this.body );
			cb(gltf.scene);
		},
			// called while loading is progressing
			(xhr) => {
				// console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
				if (xhr.loaded === xhr.total) {
					console.log('done loading gltf')
				}
			},
			// called when loading has errors
			(error) => {
				console.log( 'An error happened', error );
			}
		);
	}

	_loadBody = (url, cb) => {
		// Load a glTF resource
		loader.load(url, (gltf) => {

			gltf.scene.traverse( ( child ) => {
				// console.log("child name", child.name, child)
				// get bones
				if (child.name === "Hips") {
					this.hips = child;
					this.hips.initialPosition = new THREE.Vector3().copy(this.hips.position);
				} else if (child.name === "Spine") {
					this.spine = child
				} else if (child.name === "Neck") {
					this.neck = child
				} else if (child.name === "Head") {
					this.head = child
				} else if (child.name === "head") {
					this.defaultHead = child
				}

				if ( child.isMesh ) {
					// console.log("child name", child.name)
					child.castShadow = true;
					child.receiveShadow = true;

					child.material.metalness = 0;

					if (child.name.includes("shirt")) {
						child.material.color = child.material.color.multiplyScalar(1.25)
						child.material.needsUpdate = true
						this.shirt = child;
					} else if (child.name.includes("hair")) {
						child.material.color = new THREE.Color(0x442727).convertGammaToLinear()
						// child.material.color = child.material.color.multiplyScalar(1.25)
						child.material.needsUpdate = true
						this.hair = child;
					} else if (child.name.includes("face")) {
						// console.log('got face')
						child.material.color = new THREE.Color(0xfdbba1).convertGammaToLinear()
						// child.material.color = child.material.color.multiplyScalar(1.25)
						child.material.needsUpdate = true
						this.face = child
					} else if (child.name.includes("eyeL") || child.name.includes("eyeR")) {
						const newEyeMaterial = child.material.clone()
						// console.log("child", child.material)
						child.material = newEyeMaterial
						child.material.color = new THREE.Color(0xffffff).convertGammaToLinear()
						child.material.needsUpdate = true
					} else if (child.name.includes("body")) {
						// child.material.color = child.material.color.multiplyScalar(1.25)
						child.material.color = new THREE.Color(0xfdbba1).convertGammaToLinear()
						child.material.needsUpdate = true
						this.skin = child;
					} else if (child.name.includes("headRing")) {
						child.visible = false
						child.geometry.dispose()
					}
				}
			})

			// box helper for shader
			// const boxGeometry = new THREE.BoxBufferGeometry(60, 30, 30);
			// const boxMaterial =  new FresnelShader({
			// 	color1: new THREE.Color(0xff4444),
			// 	color2: new THREE.Color(0x4444ff),
			// 	scale: 1.4,
			// 	power: 1
			// })
			// const cubeHelper = new THREE.Mesh(boxGeometry, boxMaterial);
			// cubeHelper.position.set(0, 120, 0)
			// // 	return helperGroup.add(cubeHelper)
			// gltf.scene.add(cubeHelper)

			this.gltf = gltf;

			if (navigator.userAgent !== "snap") {
				this.playAnimations(this.gltf);
			}

			gltf.scene.name = "object_scene";
			this.body = gltf.scene;
			cb(this.body);
		},
			// called while loading is progressing
			(xhr) => {
				// console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
				if (xhr.loaded === xhr.total) {
					console.log('done loading gltf')
				}
			},
			// called when loading has errors
			(error) => {
				console.log( 'An error happened', error );
			}
		);
	}

	playAnimations() {
		if (this.gltf) {
			this.mixer = this.mixer || new THREE.AnimationMixer(this.gltf.scene);

			// filter out hips
			const valuesToRemove = ['Hips.position']
			const filteredAnimations = this.gltf.animations[0].tracks.filter(item => !valuesToRemove.includes(item.name))

			this.gltf.animations[0].tracks = filteredAnimations;
			// console.log("animations", filteredAnimations, this.gltf.animations[0].tracks)
			const randomStart = Utils.randomNumber(-1, 1)

			this.gltf.animations.forEach((clip) => {
				this.mixer.clipAction(clip).startAt(randomStart).play();
			});
		}
	}

	animateFace(data) {

		if (this.body && data) {
			// console.log('animating face', data, this.body)
			// only render this group
			this.tweenGroup.update();

			const positionFactor = .2;

			// console.log(this.hips.initialPosition)
			// poor man's ik
			let body = {
				position: {
					x: data.global.position.x,
					y: data.global.position.y,
					z: data.global.position.z
				}, rotation: {
					x: data.global.rotation.x,
					y: data.global.rotation.y,
					z: data.global.rotation.z
				}
			};
			// console.log('animating body', data.global.position)

			let hips = {
				position: {
					x: (data.position.x * positionFactor),
					y: this.hips.initialPosition.y + (data.position.y * positionFactor),
					z: this.hips.initialPosition.z + data.position.z
				}, rotation: {
					x: 0,
					y: data.rotation.y/4,
					z: 0
				}
			};

			let spine = {
				rotation: {
					x: data.rotation.x/3,
					y: data.rotation.y/2,
					z: data.rotation.z/2
				}
			}

			let neck = {
				rotation: {
					x: data.rotation.x - Math.PI/16,
					y: data.rotation.y,
					z: data.rotation.z
				}
			}

			// remove tweens over a certain amount
			const allGroupTweens = this.tweenGroup.getAll().length;

			// console.log("all tweens", allGroupTweens);
			if (allGroupTweens > 8) {
				this.tweenGroup.removeAll();
				// console.log("remove tweens", allGroupTweens);
			}

			// don't spin
			const rotationYDelta = Math.abs(this.body.rotation.y - body.rotation.y);

			if (rotationYDelta > Math.PI) {
				let remappedRotationY = this.body.rotation.y > 0 ? this.body.rotation.y - (2*Math.PI) : (2*Math.PI) + this.body.rotation.y;
				console.log('rotate', this.body.rotation.y, body.rotation.y, remappedRotationY)
				this.body.rotation.set(this.body.rotation.x, remappedRotationY, this.body.rotation.z)
			}

			// tween coordinates
			this._tweenPoint(this.body.position, body.position, 2000);
			this._tweenPoint(this.body.rotation, body.rotation, 2000);


			this._tweenPoint(this.hips.position, hips.position);
			this._tweenPoint(this.hips.rotation, hips.rotation);

			this._tweenPoint(this.spine.rotation, spine.rotation);
			this._tweenPoint(this.neck.rotation, neck.rotation);

			// mouth detection
			let have_morph_targets = !!this.avatarHead && this.avatarHead.morphTargetInfluences && this.avatarHead.morphTargetInfluences.length > 0;

			if (have_morph_targets) {
				// this.avatarHead.morphTargetInfluences[1] = Math.max(data.expressions.sad, data.expressions.disgusted);

				// this.avatarHead.morphTargetInfluences[0] = data.blendshapes.jawOpen;

				// console.log(this.avatarHead.morphTargetInfluences[0]); // this is just a value
				// this.avatarHead.morphTargetInfluences[2] = Math.max(data.expressions.sad, data.expressions.disgusted);
				const eyeFactor = 2;
				let eyes;
				// console.log("mmouse",data.mousePosition)
				if (data.mousePosition) {
					eyes = {
						eyeLookUpLeft: Math.min(data.mousePosition.y, 0) * -1, // up
						eyeLookOutLeft: Math.max(data.mousePosition.x, 0) * 1, // out
						eyeLookDownLeft: Math.max(data.mousePosition.y, 0) * 1, // down
						eyeLookInLeft: Math.min(data.mousePosition.x, 0) * -1, // in
						eyeLookUpRight: Math.min(data.mousePosition.y, 0) * -1, // up
						eyeLookOutRight: Math.min(data.mousePosition.x, 0) * -1, // out
						eyeLookDownRight: Math.max(data.mousePosition.y, 0) * 1, // down
						eyeLookInRight: Math.max(data.mousePosition.x, 0) * 1 // in
					}
				} else {
					eyes = {
						eyeLookUpLeft: Math.min(data.rotation.x, 0) * -eyeFactor, // up
						eyeLookOutLeft: Math.max(data.rotation.y, 0) * eyeFactor, // out
						eyeLookDownLeft: Math.max(data.rotation.x, 0) * eyeFactor, // down
						eyeLookInLeft: Math.min(data.rotation.y, 0) * -eyeFactor, // in
						eyeLookUpRight: Math.min(data.rotation.x, 0) * -eyeFactor, // up
						eyeLookOutRight: Math.min(data.rotation.y, 0) * -eyeFactor, // out
						eyeLookDownRight: Math.max(data.rotation.x, 0) * eyeFactor, // down
						eyeLookInRight: Math.max(data.rotation.y, 0) * eyeFactor // in
					}
				}

				this._tweenBlendshape(this.avatarHead.morphTargetInfluences, eyes, tweenDuration);

				// console.log(data.blendshapes.jawOpen, data.expressions.surprised)
				let faceMorphs = {
					jawOpen: data.blendshapes.jawOpen,
					mouthSmileLeft: data.expressions.happy,
					mouthSmileRight: data.expressions.happy,
					mouthFrownLeft: data.expressions.sad,
					mouthFrownRight: data.expressions.sad,
					mouthLeft: data.blendshapes.mouthLeft,
					mouthRight: data.blendshapes.mouthRight,

					// mouthPucker: data.blendshapes.mouthPucker,
					browInnerUp: Math.max(data.expressions.suprised, data.expressions.happy)
				}

				this._tweenBlendshape(this.avatarHead.morphTargetInfluences, faceMorphs, tweenDuration);

				if (!this.eyeBlink) {
					this.eyeBlink = this._tweenRepeatBlendshape(this.avatarHead.morphTargetInfluences, "eyeBlinkLeft", 1, 200);
					this._tweenRepeatBlendshape(this.avatarHead.morphTargetInfluences, "eyeBlinkRight", 1, 200);
				}
			}

		} else {
			console.log('play default animation')
			this.playAnimations(this.gltf);
		}


	}

	getNeckPosition = () => {
		// console.log(this.neck.rotation)
		return this.neck.rotation
	}

	_tweenPoint = (target, endParams, duration=tweenDuration) => {
		// todo: garbage collect tweens

		new TWEEN.Tween( target, this.tweenGroup )
			.to( endParams, duration )
			.easing( TWEEN.Easing.Exponential.Out )
			.start();
	}

	_tweenBlendshape = (target, variables, endParams, duration=tweenDuration) => {
		Object.keys(variables).map( (key) => {
			// let blendshapeKey = AvatarSDK.blendshapes()[value];
			// console.log("hello", key, AvatarSDK.blendshapes()[key], variables[key]);
			return this._tweenVariable(target, AvatarSDK.blendshapes()[key], variables[key], duration);
		})
	}

	_tweenVariable = (target, variable, endParams, duration=tweenDuration) => {
		// todo: garbage collect tweens
		let tweenTo = {};
		tweenTo[variable] = endParams; // set the custom variable to the target

		return new TWEEN.Tween( target, this.tweenGroup )
			.to( tweenTo, duration )
			.easing( TWEEN.Easing.Exponential.Out )
			.start();
	}

	_tweenRepeatBlendshape = (target, variable, endParams, duration=tweenDuration) => {
		const mappedBlendshapeVariable = AvatarSDK.blendshapes()[variable];
		return this._tweenRepeatVariable(target, mappedBlendshapeVariable, endParams, duration);
	}

	_tweenRepeatVariable = (target, variable, endParams, duration=tweenDuration) => {
		// todo: garbage collect tweens
		let tweenTo = {};
		let tweenBack = {};

		tweenTo[variable] = endParams; // set the custom variable to the target
		tweenBack[variable] = 0; // set the custom variable to the target

		// don't add these to tween group, cause itll get cancelled
		let tweenA = new TWEEN.Tween( target )
			.to( tweenTo, duration )
			.delay(3500)
			.easing( TWEEN.Easing.Exponential.Out )
			.start()

		let tweenB = new TWEEN.Tween( target )
			.to( tweenBack, duration*2 )
			// .delay(0)
			.easing( TWEEN.Easing.Exponential.Out )

		tweenA.chain(tweenB);
		tweenB.chain(tweenA);

		return tweenA
	}

	updateAnimations(delta) {
		// only render this group
		if (this.tweenGroup) this.tweenGroup.update();
		if ( this.mixer ) this.mixer.update( delta );
	}

	_pulseObject = (object) => {
		// play morphs
		// if (this.defaultHead) {
		// 	this._pulseObject(this.defaultHead)
		// }
		object.traverse( child => {
			if (child.materials) {
				console.log(child, child.materials)
				// child.materials[0].transparent = true;
				// child.materials[0].opacity = 1 + Math.sin(new Date().getTime() * .0025);//or any other value you like
				// child.visible = false;
			}
			return
		});
	}

	_updateSkin = (skinColor, shirtColor) => {
		// console.log("update skin", this.skin, this.shirt)
		// if (this.defaultHead && this.defaultHead.material) {
		// 	// helpful material guide https://threejsfundamentals.org/threejs/lessons/threejs-materials.html
		// 	const defaultHeadMaterial = this.defaultHead.material.copy()
		// 	this.defaultHead.material.map = null;
		// 	this.defaultHead.material.color.set( this.skinColor );
		// 	this.defaultHead.material.needsUpdate = true;
		// }

		if (this.hair && this.hair.material) {
			// helpful material guide https://threejsfundamentals.org/threejs/lessons/threejs-materials.html
			// this.hair.material.map = null;
			this.hair.material.color.set(this.hairColor).convertGammaToLinear()
			this.hair.material.needsUpdate = true;
		}

		if (this.skin && this.skin.material) {
			// if (!this.shader) {
			// 	this.face.material = new FresnelShader({
			// 		color1: new THREE.Color(this.skinColor).multiplyScalar(1.2),
			// 		color2: new THREE.Color(this.skinColor),
			// 		scale: 1,
			// 		map: this.face.material.map,
			// 		power: 1
			// 	})

			// 	this.skin.material = new FresnelShader({
			// 		color1: new THREE.Color(this.skinColor).multiplyScalar(1.2),
			// 		color2: new THREE.Color(this.skinColor),
			// 		scale: 1,
			// 		map: this.skin.material.map,
			// 		power: 1
			// 	})

			// } else {
				// console.log("skinning")
				this.face.material = new THREE.MeshStandardMaterial({
					skinning: true,
					roughness: .75,
					// reflectivity: .5,
					metalness: 0,
					map: this.face.material.map,
					// aoMapIntensity: 1,
					emissive: new THREE.Color(this.skinColor).convertGammaToLinear().multiplyScalar(.2),
					color: new THREE.Color(this.skinColor).convertGammaToLinear().multiplyScalar(1.4),
				})

				this.skin.material = new THREE.MeshStandardMaterial({
					skinning: true,
					roughness: .75,
					// reflectivity: .5,
					metalness: 0,
					map: this.skin.material.map,
					emissive: new THREE.Color(this.skinColor).convertGammaToLinear().multiplyScalar(.2),
					color: new THREE.Color(this.skinColor).convertGammaToLinear().multiplyScalar(1.4),
				})
			// }
			// }
		}

		// set shirt
		if (this.shirt && this.shirt.material) {
			if (this.shader) {
				this.shirt.material = new FresnelShader({
					color1: new THREE.Color(this.shirtColor).multiplyScalar(1.25).lerp(new THREE.Color().copy(this.body.parent.background), 0.7),
					color2: this.shirtColor,
					scale: 1,
					map: this.shirt.material.map
					// power: 10
				})
			} else {
				this.shirt.material = new THREE.MeshPhysicalMaterial({
					skinning: true,
					roughness: .5,
					// reflectivity: .5,
					metalness: 0,
					emissive: new THREE.Color(this.shirtColor).lerp(new THREE.Color().copy(this.body.parent.background), 0.5),
					color: this.shirtColor
				})

			}
		}



	}

	_hideObject = (object) => {
		object.traverse( child => {
			// if (child instanceof THREE.Mesh) {
			if (child.isMesh) {
			// 	console.log("hide", child.geometry, child.material)
				child.visible = false;
				child.geometry.dispose()
				child.material.dispose()
			}
			return
		});
	}
}

export default SceneAvatar;
