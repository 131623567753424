import { Auth, Database, Functions, Timestamp, Analytics } from './Firebase';
import { FullStoryAPI } from "react-fullstory";

// const avatarList = {
//   cole: "d3aafa74-9dda-434b-a4c6-3f80d4b11e2f",
//   peter: "bbbd361d-b5ad-4c41-a64c-1fcf431b57f2",
//   peter2: "773f0d30-b3b1-46bd-8cd5-120879da9fad",
//   peterStatic: "f842cce0-a379-49b8-ad67-eb67388b6e36",
//   anand: "b089b4b8-f655-4b7f-bd0a-0cbe4b654830",
//   anand2: "e849e220-f04c-4c36-aa7b-0f76c6ac23a8",
//   amy: "dee6419c-b069-4c48-9e23-03aac19c308c",
//   chewy: "45e49fed-a990-4db1-87e0-c6eab9aa8e8a",
//   rob: "49e5bc53-fccf-4200-8b6d-125a5fbf366c",
//   elaine: "4f31d613-7bbf-4a98-b18d-764d04b37819",
//   woman: "8f4dbc70-599f-4179-a0db-b663c88e8e3e"
// }

class API {

	static setLocalStorage(name, data) {
		localStorage.setItem(name, JSON.stringify(data));
	}

	static getLocalStorage(name) {
		if ( localStorage.getItem(name) ) {
			return JSON.parse(localStorage.getItem(name));
		} else {
			return false;
		}
	}

	static setSessionStorage(name, data) {
		sessionStorage.setItem(name, JSON.stringify(data));
	}

	static getSessionStorage(name) {
		if ( sessionStorage.getItem(name) ) {
			return JSON.parse(sessionStorage.getItem(name));
		} else {
			return false;
		}
	}

	static getResource(name, cb) {
		// check if session
		if (this.getSessionStorage(name)) {
			cb(this.getSessionStorage(name));
		} else {
			console.log(`resource ${name} doesnt exist so setting it`);
			Database.collection("resources").doc(name).get().then((doc) => {
				if (doc.exists) {
					this.setSessionStorage(name, doc.data());
					cb(doc.data());
				} else {
					console.log("No such data!");
				}
			}).catch(function(error) {
				console.log("Error getting document:", error);
			});
		}
	}

	static getMutedState() {
		// todo: hack to fix no local mutedState sign in
		if ( localStorage.getItem("mutedState") ) {
			// console.log("local mute state", JSON.parse(localStorage.getItem("mutedState")))
			return JSON.parse(localStorage.getItem("mutedState"));
		} else {
			localStorage.setItem("mutedState", JSON.stringify(false));
			// console.log("no mute state", JSON.parse(localStorage.getItem("mutedState")))
			return JSON.parse(localStorage.getItem("mutedState"))
		}
	}

	static setMutedState(bool) {
		localStorage.setItem("mutedState", JSON.stringify(bool));
	}

	static identifyUser(user) {
		// identify firebase analytics user
	    Analytics.setUserId(user.uid)

		const display_name = user.displayName.split(' ')
		const first_name = display_name[0]
		const last_name = display_name[display_name.length - 1]

		const role = user.emailVerified && user.email.match(".*@spatial[.]is") ? "admin" : user

		Analytics.setUserProperties({
			first_name: first_name,
			last_name: last_name,
			display_name: display_name,
			role: role
		})

		// Identify fullstory method
		FullStoryAPI("identify", user.uid, {
			displayName: user.displayName,
			email: user.email
		});
	}

	static getUserAuth() {
		return new Promise((resolve, reject) => {
			// let user = Auth.currentUser;

			Auth.onAuthStateChanged((user) => {
				if (user) {
					// console.log("auth", user)
					this.identifyUser(user)
					resolve(user)
				} else {
					// console.log("no user logged");
					// window.location.replace(`/login${window.location.pathname}`);					console.log('no auth');
					reject()
				}
			})
		})
	}

	static isAdmin() {
		return new Promise((resolve, reject) => {
			this.getUserAuth().then((user) => {
				if (user) {
					if (user.emailVerified && user.email.match(".*@spatial[.]is")) {
						// console.log('user is spatial admin', user.uid);
						resolve()
					} else {
						// console.log('user aint admin');
						reject()
					}
				} else {
					// console.log('no user');
					reject()
				}
			}).catch(() => {
				// console.log("user doesn't exist");
				// reject()
			})
		})
	}

	static getAdmin() {
		return new Promise((resolve, reject) => {
			this.getUserAuth().then((user) => {
				if (user) {
					// this.identifyUser(user)
					// console.log('auth change', user.emailVerified)
					if (user.emailVerified && user.email.match(".*@spatial[.]is")) {
						// console.log('user is spatial admin');
						resolve()
					} else {
						// console.log('user logged in but isnt admin');
						window.location.replace(`/`);
						reject()
					}
				}
			}).catch(() => {
				// console.log("redirect");
				// window.location.replace(`/`);
				// console.log("redirect since user doesn't exist");
				window.location.replace(`/login${window.location.pathname}`);
				// reject()
			})
		})
	}

	static getCurrentUUID() {
		// todo: hack to fix no local storage sign in
		if ( localStorage.getItem("currentUser") ) {
			return JSON.parse(localStorage.getItem("currentUser")).uid;
		} else {
			console.log("redirect because not logged in", window.location);
			// window.location
			window.location.replace(`/login${window.location.pathname}`);
		}
	}

	static getCurrentCachedUser(cb) {
		let currentUID = this.getCurrentUUID();
		if (currentUID) {
			this.getUser(currentUID, (data) => {
				// console.log("get current", data)
				cb(data);
			});
		}
	}

	static formatUID(UID) {
		let formattedUID = UID;

		if (formattedUID.charAt(0) !== "+") {
			formattedUID = `+${formattedUID}`
		}
		return formattedUID;
	}

	static getUserInvite(UID, cb) {
		Database.collection("invites").doc(this.formatUID(UID)).get().then((doc) => {
			if (doc.exists) {
				// console.log('get user', doc.data());
				cb(doc.data());
			} else {
				// doc.data() will be undefined in this case
				console.log("No such invite!");
				cb(false);
			}
		}).catch(function(error) {
			console.log("Error getting document:", error);
		});
	}

	static getUserByName(name, cb) {
		const capitalizedName = name.charAt(0).toUpperCase() + name.slice(1)

		Database.collection("users").where("firstName", "==", capitalizedName).get().then((querySnapshot) => {
			const users = []
			querySnapshot.forEach((doc) => {
	            // doc.data() is never undefined for query doc snapshots
				if (doc.exists) {
					// console.log('get user', doc.id);
					users.push(doc.data())
				} else {
					// doc.data() will be undefined in this case
					console.log("No such user!");
					cb(false);
				}
	        });
	        cb(users[0])
		}).catch(function(error) {
			console.log("Error getting document:", error);
		});
	}

	static getUser(UID, cb) {
		Database.collection("users").doc(this.formatUID(UID)).get().then((doc) => {
			if (doc.exists) {
				// console.log('get user', doc.data());
				cb(doc.data());
			} else {
				// doc.data() will be undefined in this case
				console.log("No such user!");
				cb(false);
			}
		}).catch(function(error) {
			console.log("Error getting document:", error);
		});
	}

	static getUsers(randomPeopleAmount=false, cb) {
		// Database.collection("users").get().then((querySnapshot) => {
		Database.collection("users").get().then((querySnapshot) => {
			let people = {};
			// let currentUID = this.getCurrentUUID();

			if (randomPeopleAmount) {
				people = this._generateRandomUsers(randomPeopleAmount);
			}

			querySnapshot.forEach((doc) => {
				// console.log("query roster", doc.id, doc.data().firstName);
				if (doc.data()) {
					// console.log("doc data", doc.data(), doc.data().uid, currentUID)
					// filter me out so i dont' show up
					// if (doc.data().uid !== currentUID) {
					people[doc.id.trim()] = doc.data();
					// }
				}
			});

			cb(people);
		});
	}


	static _generateRandomUsers(numberOfRandos) {
		let randoms = {};
		let lastSeen = new Timestamp.now();

		[ ...Array(numberOfRandos).keys() ].map( (key, i) => {
			i = i+1;
			// console.log("randos", key, i)

			return randoms[key] = {
				displayName: `Random Person ${i}`,
				email: `random${i}@email.com`,
				lastSeen: lastSeen,
				avatar: {
					bodyType: Math.round(Math.random())
				},
				uid: key
			}
		});

		return randoms;
	}

	// room utils
	// static formatRoomsToArray(UIDs, cb) {
	// 	const roomRef = Database.collection("rooms");

	// 	roomRef.get().then((querySnapshot) => {
	// 		// get all matches
	// 		querySnapshot.forEach((room) => {
	// 			const arrayedUsers = [];
	// 			// console.log("map", room.data().users)
	// 			Object.keys(room.data().users).map(key => {
	// 				return arrayedUsers.push(key)
	// 			})
	// 			// console.log("arrayed", arrayedUsers)

	// 			Database.collection("rooms").doc(room.id).update({
	// 			    lastActiveTime: room.data().lastActive,
	// 			    lastActive: FieldValue.delete()
	// 		    });

	// 			// format object to array
	// 			console.log(arrayedUsers)
	// 			if (room.data().users instanceof Array) {
	// 				// console.log('is array')
	// 			} else {
	// 				Database.collection("rooms").doc(room.id).update({
	// 					users: arrayedUsers
	// 				})
	// 			}
	// 		});

	// 	})
	// }

	// static formatRoomsToObject(UIDs, cb) {
	// 	const roomRef = Database.collection("rooms");

	// 	roomRef.get().then((querySnapshot) => {
	// 		// get all matches
	// 		querySnapshot.forEach((room) => {
	// 			const objectUsers = {};
	// 			// console.log("map", room.data().users)
	// 			room.data().usersArray.map(key => {
	// 				console.log("arary", key)
	// 				return objectUsers[key] = true
	// 			})
	// 			console.log("objected", objectUsers)

	// 			// Database.collection("rooms").doc(room.id).update({
	// 			    // test: FieldValue.delete()
	// 			    // usersArray: room.data().users,
	// 				// users: objectUsers
	// 		    // });

	// 			// format object to array
	// 			Database.collection("rooms").doc(room.id).set({
	// 				users: objectUsers
	// 			}, {
	// 				merge: true
	// 			})
	// 		});

	// 	})
	// }


	// room services
	static getRooms(UIDs, cb) {
		const roomsRef = Database.collection("rooms")
		let roomsQueryRef = roomsRef.orderBy("lastActiveTime", "desc").limit(25);
		// console.log(UIDs instanceof Array, UIDs)
		if (UIDs instanceof Array) {
	        roomsQueryRef = roomsQueryRef.where("users", "array-contains-any", UIDs)
	    }

		const filteredRooms = {};
		roomsQueryRef.get().then((querySnapshot) => {
			// get all matches
			querySnapshot.forEach((room) => {
				// check if .users contains all of the listed UIDs since array-contains-any
				// deletes any of the objects it contains
				if (UIDs) {
					if (UIDs.every(i => room.data().users.includes(i))) {
						// delete filteredRooms[room.id]
						filteredRooms[room.id] = room.data()
					}
				} else {
					filteredRooms[room.id] = room.data()
				}
			});
			cb(filteredRooms)
		})
	}

	static lookUpRoom(UIDs, cb) {
		// console.log("calling https rooms-list")
		const roomLookUp = Functions.httpsCallable('rooms-lookUp');
		roomLookUp({users: UIDs}).then((result) => {
		  // Read result of the Cloud Function.
		  const matchedRoomID = result.data;
		  // console.log("result", matchedRoomID)
		  cb(matchedRoomID)
		});
	}

	static getRoom(roomID, cb) {
		const roomRef = Database.collection("rooms").doc(roomID);

		roomRef.get().then((doc) => {
			if (doc.exists) {
				// this.joinChat(doc.id);
				cb(doc.data());
			} else {
				// console.log("No such room!");
				cb(false);
			}
		}).catch(function(error) {
			console.log("Error getting room", error);
		});
	}

	static createRoom(userArray, cb) {
		const roomsRef = Database.collection("rooms");
		let usersFormatted = {};

		console.log("creating a room!");
		userArray.map(key => {
			return usersFormatted[key] = true
		});
		// console.log("users is array", usersFormatted)

		roomsRef.add({
			"users": usersFormatted,
			"lastActive": new Date()
		}).then((doc) => {
			// return room id
			cb(doc.id);
		});
	}

	static refreshSnap(collection, doc) {
		console.log('fire', collection, doc)
		// return
		const forceSnap = Functions.httpsCallable('snap-forceSnap');

		return forceSnap({collection: collection, doc: doc})
	}

	static addInviteToRoom(roomID, userObject) {
		const roomsRef = Database.collection("rooms").doc(roomID);
		let usersFormatted = {};

		Object.keys(userObject).map(key => {
			return usersFormatted[key] = true
		});

		roomsRef.set({
			invites: {...usersFormatted}
		}, {
			merge: true
		})
	}
	// chat services
	static getChat(ID, cb) {
		const chatRef = Database.collection("Chats").doc(ID);

		chatRef.get().then((doc) => {
			if (doc.exists) {
				this.joinChat(doc.id);
				cb(doc.data());
			} else {
				console.log("No such chat!");
			}
		}).catch(function(error) {
			console.log("Error getting chat", error);
		});
	}

	static joinChat(chatID, peerID, cb) {
		const docRef = Database.collection("Chats").doc(chatID).collection("activeUsers").doc(this.getCurrentUUID());

		docRef.set({
			peerID: peerID,
			lastSeen: new Date()
		}).then( () => {
			return cb()
		}).catch(function(error) {
			console.error("Error writing document: ", error);
		});

	}

	static leaveChat(chatID) {
		this.removePeerFromChat(chatID, this.getCurrentUUID());
	}

	static removePeerFromChat(chatID, peerUID) {
		const docRef = Database.collection("Chats").doc(chatID).collection("activeUsers").doc(peerUID);

		docRef.delete().then(() => {
			console.log("Document successfully deleted!");
		}).catch(function(error) {
			console.error("Error removing document: ", error);
		});
	}

	static unsubscribeWatchChatUsers(ID, cb) {
		console.log("unsubscribing", ID);
		Database.collection("Chats").doc(ID).collection("activeUsers").onSnapshot(() => {
			cb();
		});
	}

	static watchChatUsers(chatID, cb) {
		// todo: no need to watch all users i don't think
		console.log("snapshot")
		return Database.collection("Chats").doc(chatID).collection("activeUsers").onSnapshot((querySnapshot) => {
			let users = {};

			querySnapshot.forEach((doc) => {
				users[doc.id] = doc.data();
			});

			cb(users);
		});
	}

	// static chatRef.add({
	// 	users: [ID, this.getCurrentUUID()]
	// }).then((doc) => {
	// 	cb(doc.id);
	// });

	static watchCollection(collection) {
		// todo: no need to watch all users i don't think
		return Database.collection(collection).onSnapshot((querySnapshot) => {
			console.log("snapshot")
			querySnapshot.forEach((doc) => {
				// this assumes data don't change for others
				if (doc.type === "added") {
					// change.doc here is new a new document
					console.log("added", doc)
				}
			});

		});
	}

	// find existing chat
	static getChatID(ID, cb) {
		const chatRef = Database.collection("Chats");

		// query for all chats with My UUID as a user
		Database.collection("Chats").where("users", "array-contains", this.getCurrentUUID()).get().then((querySnapshot) => {

			let chatID = null;
			// check for other UUID in all my uuid results
			querySnapshot.forEach((doc) => {
				if (doc.data().users.includes(ID)) {
					// console.log("found in each:", doc.exists, doc.data());
					chatID = doc.id;
				}
			});

			if (!chatID) {
				console.log("No such chat so creating one!");
				chatRef.add({
					"users": [ID, this.getCurrentUUID()],
					"activeUsers": [ID, this.getCurrentUUID()]
				}).then((doc) => {
					cb(doc.id);
				});
			} else {
				// return existing chat
				cb(chatID);
			}

		}).catch(function(error) {
			console.log("Error getting document:", error);
		});
	}

	// get chat users
	static getChatUsers(ID, cb) {
		Database.collection("Chats").doc(ID).get().then((doc) => {
			if (doc.exists) {
				cb(doc.data());
			} else {
				console.log("No such user!");
			}
		}).catch(function(error) {
			console.log("Error getting document:", error);
		});
	}

	// get chat userID
	static getOtherChatUserID(ID, cb) {
		this.getChatUsers(ID, (array) => {
			const filteredData = array.users.filter( userID => userID !== this.getCurrentUUID() );
			cb(filteredData[0]);
		})
	}

	static setCurrentUserPlayerUID(playerUID) {
		Database.collection("users").doc(this.getCurrentUUID()).set({
			playerUID: playerUID
		}, {
			merge: true
		});
	}

	static setUserData(UID, userData, cb) {
		const collectionURL = window.location.pathname.includes("/user") ? "users" : "invites"
		Database.collection(collectionURL).doc(UID).set({
			...userData
		}, {
			merge: true
		}).then(() => {
			cb();
		});
	}

	static setAvatarData(UID, userData, cb) {
		const collectionURL = window.location.pathname.includes("/user") ? "users" : "invites"

		Database.collection(collectionURL).doc(UID).set({
			...userData,
			phoneNumber: UID
		}, {
			merge: true
		}).then(() => {
			cb();
		});
	}

	static setInviteUserData(UID, userData, cb) {
		Database.collection("invites").doc(UID).set({
			...userData
		}, {
			merge: true
		}).then(() => {
			cb();
		});
	}

	static setInviteAvatarData(UID, userData, cb) {
		Database.collection("invites").doc(UID).set({
			...userData,
			phoneNumber: UID
		}, {
			merge: true
		}).then(() => {
			cb();
		});
	}

	static setCurrentUserAvatarData(avatarCode, modelInfo, cb) {
		Database.collection("users").doc(this.getCurrentUUID()).set({
			avatarCode, modelInfo
		}, {
			merge: true
		}).then(() => {
			cb();
		});
	}

	static setCurrentUserAvatarCode(avatarCode) {
		Database.collection("users").doc(this.getCurrentUUID()).set({
			avatarCode: avatarCode
		}, {
			merge: true
		});
	}

	static setCurrentUserModelInfo(modelInfo) {
		Database.collection("users").doc(this.getCurrentUUID()).set({
			modelInfo: modelInfo
		}, {
			merge: true
		});
	}

	// static setCurrentAvatarMovement(data) {
	// 	// console.log('setting data', data)
	// 	// we usually at the parent level only fire this if more than 1
	// 	RealtimeDatabase.ref('users/' + this.getCurrentUUID()).set(data);
	// }

	// static watchAvatarMovement(userID, cb) {
	// 	return RealtimeDatabase.ref('users/' + userID).on('value', (snapshot) => {
	// 		// console.log('getting snap', snapshot.val())
	// 		cb(snapshot.val());
	// 	});
	// }
}

export default API;