import React from 'react';
import { withRouter } from "react-router-dom";

// components
import API from './API.js';
import Utils from './Utils.js';

import AvatarSDK from './AvatarSDK.js';

class AvatarCreation extends React.Component {

	constructor(props)
	{
		super(props);

		this.state ={
			file: null,
			autoRotate: !Utils.isUserAgent("snap"),
			avatarGenerating: false,
			firstNameValue: this.props.firstName || "First",
			lastNameValue: this.props.lastName || "Last",
			phoneValue: this.props.phoneNumber || "Phone number",
			playerUID: this.props.playerUID || null,
			status: {
				message: null,
				animation: null
			}
		};

		this.onFileChange = this.onFileChange.bind(this);
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevStates) {
	}

	componentWillUnmount() {
	}

	render() {

		return (
			<div>

				<div
					className={`flex items-center justify-center ${this.props.show ? "scaleIn" : "scaleOut"}`}
				>
					<div className={`flex flex-column mb-r exclusion absolute bottom-0 ${this.state.avatarGenerating ? "scaleOut" : "scaleIn"}`}>
						<input
							className="z2 mb2 center hidden"
							ref={fileInput => this.fileInput = fileInput}
							type="file"
							accept="image/*"
							onChange={this.onFileChange}
						/>

						<div className="mxn1 max-width-1">
					    	<div className="col col-6 px1">
								<input
									className="full-width scaleIn pill button-text mb3"
							    	type="text"
							    	id="firstNameValue"
							    	name="First"
							    	value={this.state.firstNameValue}
									onFocus={this.onFirstNameFocus}
									onBlur={this.onFirstNameBlur}
									onChange={this.onFirstNameChange}
						    	/>
					    	</div>

					    	<div className="col col-6 px1">
								<input
									className="full-width scaleIn pill button-text mb3"
							    	type="text"
							    	id="lastNameValue"
							    	name="Last"
							    	value={this.state.lastNameValue}
									onFocus={this.onLastNameFocus}
									onBlur={this.onLastNameBlur}
									onChange={this.onLastNameChange}
						    	/>
					    	</div>
				    	</div>

						<input
							className="scaleIn pill button-text mb3"
					    	type="text"
					    	name="Phone number"
					    	value={this.state.phoneValue}
							onFocus={ this.onPhoneFocus }
							onBlur={ this.onPhoneBlur }
							onChange={this.onPhoneChange}
				    	/>

						<button
							className="scaleIn pill-button mb3"
							onClick={this._triggerInputFile}
						>
							select a photo
						</button>

						{/*	<button
								className="scaleIn pill-button mb3"
								onClick={this._generateAvatar}
							>
								use profile photo
							</button>

						<button
							className="scaleIn pill-button-inverse bg-white black"
							onClick={ () => {
								this.props.history.push('selfie')
							}}
						>
							Donzo
						</button>
						*/}
					</div>
					<h4 className={`absolute mb4 pb4 nowrap ${this.state.avatarGenerating ? "scaleIn fadePulse" : "scaleOut"}`}>
						Generating your face...
					</h4>
				</div>
			</div>
		);
	}

	_triggerInputFile = () => {
		this.fileInput.click();
	}


	onFirstNameFocus = (event) => {
		// console.log("focus", event.target.name, event.target.value, this.state.defaultPhoneValue)
		if (event.target.value === event.target.name) {
			this.setState({
				firstNameValue: ""
			})
		}
	}

	onFirstNameBlur = (event) => {
		// console.log("unfocus", event.target.value, this.state.defaultPhoneValue)
		if (event.target.value === "") {
			this.setState({
				firstNameValue: event.target.name
			})
		}
	}

	onFirstNameChange = (event) => {
		this.setState({firstNameValue: event.target.value});
	}

	onLastNameFocus = (event) => {
		// console.log("focus", event.target.name, event.target.value, this.state.defaultPhoneValue)
		if (event.target.value === event.target.name) {
			this.setState({
				lastNameValue: ""
			})
		}
	}

	onLastNameBlur = (event) => {
		// console.log("unfocus", event.target.value, this.state.defaultPhoneValue)
		if (event.target.value === "") {
			this.setState({
				lastNameValue: event.target.name
			})
		}
	}

	onLastNameChange = (event) => {
		this.setState({lastNameValue: event.target.value});
	}

	onPhoneFocus = (event) => {
		// console.log("focus", event.target.name, event.target.value, this.state.defaultPhoneValue)
		if (event.target.value === event.target.name) {
			this.setState({
				phoneValue: ""
			})
		}
	}

	onPhoneBlur = (event) => {
		// console.log("unfocus", event.target.value, this.state.defaultPhoneValue)
		if (event.target.value === "") {
			this.setState({
				phoneValue: event.target.name
			})
		}
	}

	onPhoneChange = (event) => {
		this.setState({phoneValue: event.target.value});
	}


	onFileChange(e) {
		// console.log("file change", e, e.target.files)

		this.setState({
			file:e.target.files[0]
		}, () => {
			this._generateAvatar();
		});
	}

	_generateAvatar = () => {
		this._updateStatus("generating that beautiful face");
		this.setState({avatarGenerating: true});

		let newUsers = {}
		const formattedPhoneNumber = `+${this.state.phoneValue.replace(/\D+/g,'')}`

		let user = {
			uid: formattedPhoneNumber,
			firstName: this.state.firstNameValue,
			lastName: this.state.lastNameValue,
			phoneNumber: formattedPhoneNumber,
			playerUID: this.state.playerUID
		}

		let photoFileBlob = this.state.file;
		// console.log(user, photoFileBlob)
		const photoFilePromise = new Promise((resolve, reject) => {
			if (photoFileBlob) {
				resolve();
			} else {
				reject();
			}

		});

		photoFilePromise.then(() => {
			AvatarSDK.uploadAvatar(user, photoFileBlob, (data) => {
				if (data) {

					// set date
					data.avatar.dateUpdated = new Date()

					// console.log("final avatar data", data );
					// this.props.parentStatusMethod("finishing touches");
					// set in Firestore
					const userInviteData = {
						firstName: user.firstName,
						lastName: user.lastName,
						phoneNumber: user.phoneNumber
					}

					API.setUserData(user.uid, userInviteData, () => {
						console.log('invite set')
					});

					API.setAvatarData(user.uid, data, () => {
						newUsers[user.uid] = data;

						this.props.parentUserMethod(newUsers);

						this.setState({
							file: null,
							firstNameValue: "First",
							lastNameValue: "Last",
							phoneValue: "Phone number",
							avatarGenerating: false
						}, () => {
							// this needs to set after data is written
							// window.location.reload(false);
						});
					});

				} else {
					// avatar generation failed
					this._updateStatus("Face fail, try another photo");

					this.setState({
						file: null,
						avatarGenerating: false
					});
				}

			});
		}).catch(error => console.error(error));
	}

	_resetState = () => {
		this.props.history.push('/');
	}

	_updateStatus = (message, animation="fadePulse") => {

		this.setState({
			status: {
				message: message,
				animation: animation
			}
		});

	}
}

export default withRouter(AvatarCreation);
