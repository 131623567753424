import React from 'react';
import ReactDOM from 'react-dom';

import TeleportRouter from './Router';

// css
import './css/App.css';
import './css/Components.css';
import './css/Animations.css';

ReactDOM.render(
	<TeleportRouter />,
	document.getElementById('root')
);