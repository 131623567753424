import * as React from 'react';

// Router stuff
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Analytics } from './components/Firebase';
import FullStory from "react-fullstory";

import Utils from "./components/Utils.js";

// Routes
// import Home from './Home.js';
import Home from './Home.js';
import Login from './Login.js';
import Room from './Room.js';
import Avatar from './Avatar.js';

import User from './User.js';
import Admin from './Admin.js';

import Redirect from './Redirect.js';

class TeleportRouter extends React.Component {

	componentDidMount() {
		// set bg color
		document.documentElement.classList.add(`bg-${Utils.isDayOrNight()}`);
		document.body.classList.add(`bg-${Utils.isDayOrNight()}`);
	}

	render() {
		return (
			<Router>
				<div>
					<Switch>
						<Route exact path="/" component={Home} />
						<Route exact path="/login" component={Login} />
						<Route exact path="/login/:id" component={Login} />

						<Route exact path="/room/:id" component={Room} />
						<Route exact path="/rooms/:id" component={Room} />

						<Route exact path="/avatar" component={Avatar} />

						<Route exact path="/user/:id" component={User} />
						<Route exact path="/users/:id" component={User} />

						<Route exact path="/invite/:id" component={User} />
						<Route exact path="/invites/:id" component={User} />

						<Route exact path="/admin" component={Admin} />

						<Route exact path="/404" component={Redirect} />
						{
							// <Route exact path="/invite" component={Invite} />
							// <Route exact path="/invite/:id" component={Invite} />
							// <Route exact path="/:type" component={Home} />
							// <Route exact path="/:type/:id" component={Home} />
						}

						<Route exact path="/:name" component={User} />
						<Route component={Redirect} />
					</Switch>
				</div>

				<FirebaseAnalytics />
				{ window.location.hostname === "localhost" || "snap" ? null : <FullStory org="YZWX0" /> }
			</Router>
		);
	}
}

function FirebaseAnalytics() {
    let location = useLocation();

    useEffect(() => {
        const page_path = location.pathname + location.search;

        Analytics.setCurrentScreen(page_path);
        Analytics.logEvent('screen_view', { page_path })
        Analytics.logEvent("page_view", { page_path });
    }, [location]);
    return null;
}

export default TeleportRouter;