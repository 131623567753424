import * as React from 'react';
import { Auth, Provider } from './components/Firebase';
import { withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";

// import API from './components/API.js';
import Utils from "./components/Utils.js";
import Scene from './components/Scene.js';
import Splash from './res/tele.glb'

class Login extends React.Component {
	constructor() {
		super();

		this.state = {
			loading: false,
			currentItem: '',
			username: '',
			items: [],
			isEntering: false,
			user: null
		}

		this.login = this.login.bind(this);
		this.bgColor = Utils.isNight() ? "bg-black" : null;
		// console.log(this.bgColor);
	}

	componentDidMount() {
		this.setState({ isEntering: true });
		let userPending = JSON.parse( localStorage.getItem("userPending") );

		if (userPending) {
			this.setState({ loading: true });
		}

		Auth.onAuthStateChanged((user) => {
			// todo: we should send someone to home screen
			// and load faster first
			// console.log('auth changed', user.displayName);
			localStorage.removeItem('userPending');

			if (user) {
				const redirectURL = this.props.match.params.id ? this.props.match.params.id : "";
		        this.props.history.push(`/${redirectURL}`);
			} else {
				console.log('no user')
			}
		});
	}

	render() {
		return (

			<div
				className={`flex items-center justify-center flex-column ${Utils.isNight() ? "bg-night" : null }`}
				style={{
					height: "100vh"
				}}
			>
				<Helmet>
					<title>Tele</title>
					<meta name="description" content="" />
				</Helmet>

				<div
					className="full-viewport absolute"
				>
					<Scene
						match = {this.props.match}
						ref="scene"
						bg = {Splash}
					/>
				</div>

				<div
					className="flex red z1 absolute exclusion"
					style={{
						fontSize: '37.5vw',
						fontWeight: '500',
						opacity: .95
					}}
				>
				</div>


				{ this.state.loading ?
					<h2 className="exclusion normal fadePulse">Hang tight...</h2>
					:
					<button className="pill-button z2 mb4" onClick={this.login}>Log In w/ Google</button>
				}
			</div>

		);
	}


	handleChange(e) {
		/* ... */
	}

	login() {
		localStorage.setItem("userPending", JSON.stringify(true));
		// console.log('localstorage', currentUser)

		Auth.signInWithRedirect(Provider).then((result) => {
			const user = result.user;
			this.setState({user});
		});
	}

}
export default withRouter(Login);
