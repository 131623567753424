import React from 'react';
import { withRouter } from "react-router-dom";

// components
import API from './components/API.js';
import Utils from './components/Utils.js';

// import MetaTags from './components/MetaTags.js'

import AvatarCreator from './components/AvatarCreator.js';
import Scene from './components/Scene.js';

class AvatarCreation extends React.Component {

	constructor(props)
	{
		super(props);

		this.state ={
			file: null,
			autoRotate: !Utils.isUserAgent("snap"),
			avatarGenerating: false,
			filteredPeople: {},
			defaultPhoneValue: "Phone number",
			phoneValue: "Phone number",
			status: {
				message: null,
				animation: null
			}
		};

	}

	componentDidMount() {
		let filteredPeople = API._generateRandomUsers(1);
		this.setState({filteredPeople});
	}

	componentDidUpdate(prevProps, prevStates) {
	}

	componentWillUnmount() {
		// unmount geoposition watch
		console.log("unmount avatar creation");
	}

	render() {

		return (
			<div>
				<div
					id="scene"
					className="full-viewport absolute"
				>
					<Scene
						ref="scene"
						showDebug={this.state.showDebug}
						backgroundColor={"random"}
						match = {this.props.match}
						people={this.state.filteredPeople}
						parentStatusMethod={this._updateStatus}
						autoRotate={this.state.autoRotate}
					/>
				</div>

				<AvatarCreator
					parentUserMethod={this._filterPeople}
				/>
			</div>
		);
	}

	_resetState = () => {
		this.props.history.push('/');
	}

	_updateStatus = (message, animation="fadePulse") => {
		const totalPeople = Object.keys(this.state.filteredPeople).length;

		if (message === "got yo face") {
			this.setState(prevState => ({
				loadedPeople: prevState.loadedPeople + 1
			}), () => {
				// console.log("got you", totalPeople, this.state.loadedPeople)
				// this callback is async and fires too fast sometimes if no avatars are real
				if (totalPeople === this.state.loadedPeople) {
					console.log("say cheese fries", `${this.state.loadedPeople}/${totalPeople}`)
					this.setState({sayCheese: true})
				}
			});
		}

		this.setState({
			status: {
				message: this.state.loadedPeople  + " / " + totalPeople,
				animation: animation
			}
		});

	}

	_filterPeople = (newPeople) => {
		// console.log('filtering people', newPeople)
		// let filteredPeople = {};
		// filteredPeople = !newPeople ? this.state.people : newPeople;
		if (newPeople) {
			this.setState({filteredPeople: newPeople});
		}
	}


}

export default withRouter(AvatarCreation);
