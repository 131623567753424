import * as THREE from 'three';

import Utils from './Utils.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// import { OrbitControls } from './OrbitControlsModified.js';

class TeleportControls extends OrbitControls {
	constructor(object, domElement) {
		super(object, domElement);

		// orbit controls defaults, will make it impossible to change these, should pass in object.* || true
		// enable up and down and right and left
		this.enableKeys = true;
		this.autoRotate = false;
		this.autoRotateSpeed = 2;
		this.keyPanSpeed = 80;
		// this.enableRotate = false;

		this.enableFirstPersonControls = false;

		this.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
		this.dampingFactor = 0.1;

		// with floor or scene
		this.screenSpacePanning = false;

		this.minDistance = 0;
		this.maxDistance = 1600;

		this.maxPolarAngle = Math.PI / 1.9;

		// setup first person controls
		if (this.enableFirstPersonControls) {
			domElement.addEventListener( 'mousedown', onMouseDown.bind(this), false );
			domElement.addEventListener( 'touchstart', onTouchStart.bind(this), false );

			let startPosition = {};

			const bindedMouseMove = onMouseMove.bind(this);
			const bindedMouseUp = onMouseUp.bind(this);

			function onMouseDown(event) {
				event.preventDefault();

				startPosition.x = event.clientX;
				startPosition.y = event.clientY;

				// pan = move, rotate = pan around
				this.mouseButtons.LEFT = THREE.MOUSE.PAN;
				this.state = 2;
				this.update();

				domElement.addEventListener( 'mousemove', bindedMouseMove, false );
				domElement.addEventListener( 'mouseup', onMouseUp.bind(this), false );
			}

			function onMouseMove(event) {
				event.preventDefault();
				const delta = 20;

				if (Math.abs(event.clientX - startPosition.x) >= delta) {
					// console.log("horizontal", Math.abs(event.clientX - startPosition.x), this.mouseButtons.LEFT)
					this.mouseButtons.LEFT = THREE.MOUSE.ROTATE;
					this.state = 0;
					this.enablePan = false;
					this.enableRotate = true;
					this.update();

					domElement.removeEventListener( 'mousemove', bindedMouseMove, false );

				} else if (Math.abs(event.clientY - startPosition.y) >= delta) {
					// console.log("vertical", Math.abs(event.clientY - startPosition.y), this.mouseButtons.LEFT)

					this.mouseButtons.LEFT = THREE.MOUSE.PAN;
					this.state = 2;
					this.enablePan = true;
					this.enableRotate = false;
					this.update();

					domElement.removeEventListener( 'mousemove', bindedMouseMove, false );
				}
			}

			function onMouseUp(event) {
				this.enablePan = true;
				this.enableRotate = true;

				// console.log('mouse is up', )
				domElement.removeEventListener( 'mousemove', bindedMouseMove, false );
				domElement.removeEventListener( 'mouseup', bindedMouseUp, false );
			}


			// start touches
			const bindedTouchMove = onTouchMove.bind(this);
			const bindedTouchEnd = onTouchEnd.bind(this);

			function onTouchStart(event) {
				// event.preventDefault();
				startPosition.x = event.touches[0].pageX;
				startPosition.y = event.touches[0].pageY;

				// pan = move, rotate = pan around
				this.state = 4;
				this.update();

				domElement.addEventListener( 'touchmove', bindedTouchMove, false );
				domElement.addEventListener( 'touchend', onTouchEnd.bind(this), false );
			}

			function onTouchMove(event) {
				// event.preventDefault();
				const delta = 20;
				// console.log(event.touches[0])

				if (Math.abs(event.touches[0].pageX - startPosition.x) >= delta) {
					// console.log("horizontal", Math.abs(event.clientX - startPosition.x), this.mouseButtons.LEFT)
					// this.mouseButtons.LEFT = THREE.MOUSE.ROTATE;
					this.state = 3;
					this.enablePan = false;
					this.enableRotate = true;
					this.update();

					domElement.removeEventListener( 'touchmove', bindedTouchMove, false );

				} else if (Math.abs(event.touches[0].pageY - startPosition.y) >= delta) {
					// console.log("vertical", Math.abs(event.clientY - startPosition.y), this.mouseButtons.LEFT)

					// this.mouseButtons.LEFT = THREE.MOUSE.PAN;
					this.state = 4;
					this.enablePan = true;
					this.enableRotate = false;
					this.update();

					domElement.removeEventListener( 'touchmove', bindedTouchMove, false );
				}
			}

			function onTouchEnd(event) {
				this.enablePan = true;
				this.enableRotate = true;
				this.update();

				domElement.removeEventListener( 'touchmove', bindedTouchMove, false );
				domElement.removeEventListener( 'touchend', bindedTouchEnd, false );
			}
		}



		// start device orientation controls
		this.object.rotation.reorder( 'YXZ' );
		this.enabledOrientation = false;

		this.deviceOrientation = {};
		this.screenOrientation = 0;
		this.alphaOffset = 0; // radians


		this.distance = () => {
			return this.object.position.distanceTo( this.target );
		}

		this.relativeDistance = () => {
			return Utils.mapRange(this.distance(), this.minDistance, this.maxDistance, 0, 1)
		}

		this.onDeviceOrientationChangeEvent = ( event ) => {
			this.deviceOrientation = event;
			// console.log("device orientation", this.deviceOrientation, event)
		};

		this.onScreenOrientationChangeEvent = () => {
			this.screenOrientation = window.orientation || 0;
			// console.log("screen orientation", this.screenOrientation)
		};

		// The angles alpha, beta and gamma form a set of intrinsic Tait-Bryan angles of type Z-X'-Y''

		this.setObjectQuaternion = (quaternion, alpha, beta, gamma, orient) => {
			// console.log("set object")
			var zee = new THREE.Vector3( 0, 0, 1 );
			var euler = new THREE.Euler();
			var q0 = new THREE.Quaternion();
			var q1 = new THREE.Quaternion( - Math.sqrt( 0.5 ), 0, 0, Math.sqrt( 0.5 ) ); // - PI/2 around the x-axis

			euler.set( beta, alpha + Math.PI, -gamma, 'YXZ' ); // 'ZXY' for the device, but 'YXZ' for us. adding Math.pi to flip 180
			quaternion.setFromEuler( euler ); // orient the device
			quaternion.multiply( q1 ); // camera looks out the back of the device, not the top
			quaternion.multiply( q0.setFromAxisAngle( zee, - orient ) ); // adjust for screen orientation
		};

		// tilt
		this.connectTiltOrientation = () => {
			// console.log('tilt')
			// // https://jsfiddle.net/EthanHermsey/e3b501cw/51/
			// // https://stackoverflow.com/questions/60678494/orbit-controls-follow-the-mouse-without-clicking-three-js

			this.minPolarAngle = 0;
			this.maxPolarAngle = Math.PI * 2;
			this.enabledOrientation = true;

			if ( window.DeviceOrientationEvent !== undefined && typeof window.DeviceOrientationEvent.requestPermission === 'function' ) {
				window.DeviceOrientationEvent.requestPermission().then( (response) => {
					// window.alert('device response', response);
					console.log('device perm', response)
					if (response === 'granted') {
						window.addEventListener( 'orientationchange', this.onScreenOrientationChange, false );
						window.addEventListener( 'deviceorientation', this.onOrientationChange, false );
					}
				}).catch( (error) => {
					console.error( 'THREE.DeviceOrientationControls: Unable to use DeviceOrientation API:', error );
				});
			} else {
				console.log("iono")
			}

			// debug with mouse
			// document.addEventListener('mousemove', (event) => {
			// 	const targetHeight = 140
			// 	const targetDistance = 200
			// 	let yRotationFactor = event.offsetX / window.innerWidth
			// 	let xRotationFactor = event.offsetY / window.innerHeight

			// 	object.position.x = Utils.modulate(yRotationFactor, [0,1], [-240, 240])
			// 	object.position.y = targetHeight + Utils.modulate(xRotationFactor, [0,1], [240, -240])
			// 	object.position.z = targetDistance + (Math.abs(Utils.modulate(yRotationFactor, [0,1], [96, -96])))
			// })
		}


		this.disconnectTiltOrientation = () => {
			window.removeEventListener( 'orientationchange', this.onScreenOrientationChange, false );
			window.removeEventListener( 'deviceorientation', this.onOrientationChange, false );

			this.enabledOrientation = false;
		};

		// this.onDeviceOrientationChangeEvent
		this.onScreenOrientationChange = () => {
			// console.log("screen orientation event", window.orientation || 0);
			// console.log("screen orientation", this.screenOrientation)
		};

		this.onOrientationChange = (event) => {
			// console.log("orientation event", event)
			const targetHeight = 180
			const targetDistance = 200

			// alpha: zRotation(0, 360), beta: xRotation (-180, 180), gamma: yRotation(-90, 90)
			let yRotationFactor = Utils.modulate(event.gamma, [-90, 90], [0, 1])
			let xRotationFactor = Utils.modulate(event.beta, [0, 90], [0, 1])
			// console.log(xRotationFactor, event.beta)
			console.log(yRotationFactor, event.gamma)

			object.position.x = Utils.modulate(yRotationFactor, [0,1], [-500, 500])
			object.position.y = targetHeight + Utils.modulate(xRotationFactor, [0,1], [240, -240])
			object.position.z = targetDistance + (Math.abs(Utils.modulate(yRotationFactor, [0,1], [128, -128])))
		};




		this.connectOrientation = () => {
			this.onScreenOrientationChangeEvent(); // run once on load

			// iOS 13+
			if ( window.DeviceOrientationEvent !== undefined && typeof window.DeviceOrientationEvent.requestPermission === 'function' ) {
				window.DeviceOrientationEvent.requestPermission().then( (response) => {
					// window.alert('device response', response);
					if (response === 'granted') {
						window.addEventListener( 'orientationchange', this.onScreenOrientationChangeEvent, false );
						window.addEventListener( 'deviceorientation', this.onDeviceOrientationChangeEvent, false );
					}
				}).catch( (error) => {
					console.error( 'THREE.DeviceOrientationControls: Unable to use DeviceOrientation API:', error );
				});
			} else {
				window.addEventListener( 'orientationchange', this.onScreenOrientationChangeEvent, false );
				window.addEventListener( "deviceorientation", this.onDeviceOrientationChangeEvent, false );
			}
			this.enabledOrientation = true;
			// this.enableRotate = false;

			// this.enabled = false;
		};

		this.disconnectOrientation = () => {
			window.removeEventListener( 'orientationchange', this.onScreenOrientationChangeEvent, false );
			window.removeEventListener( 'deviceorientation', this.onDeviceOrientationChangeEvent, false );

			this.enabledOrientation = false;
			// this.enableRotate = true;

			// this.enabled = true;
		};

		this.updateOrientation = function() {
			// console.log("enabled or not", this.enabledOrientation)
			if ( this.enabledOrientation === false ) return;

			var device = this.deviceOrientation;

			if ( device ) {
				var alpha = device.alpha ? THREE.MathUtils.degToRad( device.alpha ) + this.alphaOffset : 0; // Z
				var beta = device.beta ? THREE.MathUtils.degToRad( device.beta ) : 0; // X'
				var gamma = device.gamma ? THREE.MathUtils.degToRad( device.gamma ) : 0; // Y''
				var orient = this.screenOrientation ? THREE.MathUtils.degToRad( this.screenOrientation ) : 0; // O

				this.setObjectQuaternion( this.object.quaternion, alpha, beta, gamma, orient );
			}
		};

		this.disposeOrientation = () => {
			this.disconnectOrientation();
		};


		// stop autorotate after the first interaction
		// todo: this will probably restart everytime which i
		// should store an initialized state of autorotate
		let autorotateTimeout

		this.addEventListener('start', () => {
			// console.log("stop auto rotate")
			clearTimeout(autorotateTimeout);
			this.autoRotate = false;
		});

		// restart autorotate after the last interaction & an idle time has passed
		this.addEventListener('end', () => {
			autorotateTimeout = setTimeout(() => {
				// console.log("restart auto rotate")
				this.autoRotate = true;
			}, 3000);
		});
	}
}

export default TeleportControls;
